import React from "react";
import { Button as MUIButton } from "@mui/material";

const Button = (props) => {
  const {
    bgColor,
    fontColor,
    rect,
    width,
    height,
    className,
    children,
    onClick,
    style,
    variant,
  } = props;

  const buttonStyle = {
    color: fontColor,
    width,
    height,
    borderRadius: rect ? 0 : height / 2,
    fontWeight: 550,
    textTransform: "none",
    ...style,
  };

  return (
    <MUIButton
      variant={variant ? variant : null}
      color={bgColor}
      className={`${className}`}
      onClick={onClick}
      disableElevation
      sx={buttonStyle}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
