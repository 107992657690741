import React from "react";
import Overlay from "../../components/Overlay";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/session";

const LogOutSocial = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, []);
  return <Overlay></Overlay>;
};

export default LogOutSocial;
