import React, { useState } from "react";
import styles from "../styles/TopMenuBar.module.css";
import FilterImage from "../assets/image/filter.svg";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TopMenuBar = ({
  selectedSortBy,
  showFilters,
  onClickFiltersBtn,
  SB_FiltersAndOrders,
  menuItems,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.topBarMenu_topMenu}>
      <div
        className={styles.topBarMenu_topMenu_hideFilters_container}
        onClick={() => onClickFiltersBtn()}
      >
        <p className={styles.topBarMenu_topMenu_hideFilters_text}>
          {!showFilters
            ? SB_FiltersAndOrders?.content?.Show_filters
            : SB_FiltersAndOrders?.content?.Hide_filters}
        </p>
        <div className={styles.topBarMenu_topMenu_hideFilters_img_container}>
          <img
            className={styles.topBarMenu_topMenu_hideFilters_img}
            src={FilterImage}
            alt={"filter"}
            loading={"lazy"}
          ></img>
        </div>
      </div>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          onClick={handleClick}
          endIcon={
            <KeyboardArrowDownIcon
              sx={{ color: "#faa72d", fontSize: "30px !important" }}
            />
          }
          sx={{
            color: "#6B6C72",
            fontWeight: "700",
            fontSize: 18,
            textTransform: "none",
          }}
        >
          {SB_FiltersAndOrders?.content?.Order_by}
        </Button>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          onClick={() => handleClose()}
          disableScrollLock
        >
          {menuItems &&
            menuItems.length > 0 &&
            menuItems.map((element, index) => {
              return (
                <MenuItem
                  sx={{
                    color: `${index === selectedSortBy ? "orange" : "black"}`,
                  }}
                  onClick={element.applyFilter}
                  disableRipple
                >
                  {element.name}
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    </div>
  );
};

export default TopMenuBar;
