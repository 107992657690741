import { Calendar, Dialog, PieChart, TextArea } from "../../../components";
import { FaBookmark, FaStar } from "react-icons/fa";
import { FiBookmark, FiStar } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  addWeeklyPlan,
  getCuisineList,
  getDifficultyLevelList,
  getFieldList,
  getGroceryTypes,
  getMealLevels,
  getRDDEvaluations,
  getVegetarianTypeList,
  updatePrefer,
  updateVoting,
} from "../../../store/app/actions";
import { useDispatch, useSelector } from "react-redux";

import ActImage1 from "../../../assets/image/act-1.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { BsArrowLeft } from "react-icons/bs";
import { Button } from "@mui/material";
import CalendarIcon from "../../../assets/image/CalendarioIcon.png";
import CookImage from "../../../assets/image/cook.png";
import FoodImage1 from "../../../assets/image/tmp/food-1.png";
import { GridLoader } from "react-spinners";
import IngredientElementItem from "./IngredientElementItem";
import InstructionStepItem from "./InstructionStepItem";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import PageTemplate from "../../../components/PageTemplate";
import PrepImage from "../../../assets/image/prep.png";
import Rating from "react-rating";
import SavedIcon from "../../../assets/image/GuardadoIcon.png";
import { TbCalendarTime } from "react-icons/tb";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import VotingMealDialog from "../../../components/VotingMealDialog";
import WeeklyPlanImage from "../../../assets/image/weekly-plan.png";
import { getRecipe } from "../../../store/app/actions";
import { history } from "../../../store/history";
import styles from "./Details.module.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStoryblok } from "../../../services/storyblokService";

const VegetarianFoodDetails = (props) => {
  const urlParams = useParams();
  const id = urlParams.id;
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");

  const dispatch = useDispatch();

  const {
    preferences,
    recipeInfo,
    cuisines,
    vegetarianTypes,
    difficultyLevels,
    mealLevels,
    languages,
    rddEvaluations,
    language: appLanguage
  } = useSelector((state) => state.app);

  const inRecipe = useSelector((state) => state.app.recipe);

  const profile = useSelector((state) => state.session.profile);

  const voteInfo = inRecipe?.votes?.comments?.find?.((vote) => vote.isOwn);
  const [votingOpen, setVotingOpen] = useState(false);
  const [planOpen, setPlanOpen] = useState(false);
  const [addedMeal, setAddedMeal] = useState(false);

  const [recipe, setRecipe] = useState(null);

  const [preferred, setPreferred] = useState(recipe?.preferred);
  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");
  const [mealTime, setMealTime] = useState({});

  const language = profile?.langId ?? 1;

  const weeklyPlans = useSelector((state) => state.app.weeklyPlans);

  useEffect(() => {
    dispatch(getMealLevels());
    dispatch(getGroceryTypes());
    dispatch(getDifficultyLevelList());
    dispatch(getFieldList());
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getRDDEvaluations());
  }, [language]);

  useEffect(() => {
    if (weeklyPlans) {
      const mealId = weeklyPlans.find((ele) => {
        return ele.meals[0].id === +id;
      });
      setAddedMeal(mealId);
    }
  }, [weeklyPlans]);
  useEffect(() => {
    // let loadRecipe = recipeInfo == undefined;
    // if (!loadRecipe) {
    //   let recipe = recipeInfo.meals.find((recipe) => recipe.id === id);
    //   if (recipe === undefined) loadRecipe = true;
    //   else {
    //     loadRecipe =
    //       !recipe.ingredients?.length || !recipe.instructions?.length;
    //   }
    // }
    // if (
    //   loadRecipe ||
    //   (preferences &&
    //     preferences.meals.find((recipe) => recipe.id === id) === undefined)
    // ) {
    //   dispatch(getRecipe(id));
    // }
    dispatch(getRecipe(id));
  }, []);

  useEffect(() => {
    let meal;
    if (recipeInfo?.meals) meal = recipeInfo.meals.find((recipe) => recipe.id === id);
    if (
      (!meal || !meal.ingredients?.length || !meal.instructions?.length) &&
      preferences?.meals
    )
      meal = preferences.meals.find((recipe) => recipe.id === id);
    if (!meal || !meal.ingredients?.length || !meal.instructions?.length)
      meal = inRecipe;
    setRecipe(meal);
  }, [recipeInfo, preferences, inRecipe]);

  useEffect(() => {
    if (!recipe) return;

    setPreferred(recipe.preferred);

    const voteInfo = recipe.votes.comments.find((vote) => vote.isOwn);
    if (voteInfo !== undefined) {
      setRating(voteInfo.star);
      setText(voteInfo.comment);
    }
  }, [recipe]);

  const handlePreference = () => {
    setPreferred(!preferred);

    dispatch(updatePrefer(recipe.id, !preferred));
  };

  const handleVoting = () => {
    setVotingOpen(true);
  };

  const handleApply = () => {
    dispatch(updateVoting(recipe.id, rating, text));

    setVotingOpen(false);
  };

  const handleClose = () => {
    setVotingOpen(false);
  };

  const handleWeeklyPlan = () => {
    setPlanOpen(true);
  };

  const handleApplyPlan = () => {
    if (Object.keys(mealTime).length === 0) return;

    dispatch(addWeeklyPlan(recipe.id, mealTime.date, mealTime.time, 1));

    setMealTime({});
    setPlanOpen(false);
  };

  const handleClosePlan = () => {
    setMealTime({});
    setPlanOpen(false);
  };

  const handleChangeMealTime = (date, mealTime) => {
    setMealTime({
      date: date,
      time: mealTime,
    });
  };

  if (
    !recipe ||
    !recipe.ingredients?.length ||
    !recipe.instructions?.length ||
    !difficultyLevels ||
    !vegetarianTypes ||
    !cuisines ||
    !mealLevels ||
    !languages ||
    !rddEvaluations
  ) {
    console.log(recipe);
    return (
      <GridLoader
        color="#FC9C52"
        loading={true}
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "green",
        }}
        size={15}
        margin={2}
      />
    );
  }

  const languageId = Object.entries(languages).find(([_, lang]) => lang.code === appLanguage.toUpperCase())[0];
  const rddEvaluationsMap = Object.fromEntries(
    rddEvaluations.map((rddEval, i) => [
      i, 
      {name: rddEval.languages.find(lang => lang.id == languageId).name, value: rddEval.name}
    ])
  );

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 30,
          height: 30,
          borderRadius: 50,
          minWidth: "unset",
          backgroundColor: "white",
          position: "absolute",
          left: "80px",
          zIndex: 1,
        }}
        onClick={() => history.goBack()}
      >
        <ArrowBackIosNewIcon
          style={{
            height: 14,
            color: "#B8BABD",
          }}
        />
      </Button>

      <PageTemplate backgroundImgSrc={MainImage}>
        <div className={styles.details_pageWrapper}>
          <div className={styles.details_infoWrapper}>
            <div className={styles.details_imageContainer}>
              <img src={recipe?.image ?? FoodImage1} alt="Placeholder" />
              <Link
                to={{
                  pathname: `/vegetarian-food/detail/${recipe?.id}/votes`,
                }}
                className="d-flex justify-content-between"
                style={{ display: "block", marginTop: 10 }}
              >
                <Rating
                  emptySymbol={
                    <FiStar style={{ color: "#FC9C52", width: 22 }} />
                  }
                  fullSymbol={
                    <FaStar style={{ color: "#FC9C52", width: 22 }} />
                  }
                  initialRating={recipe?.votes.voteStar}
                  readonly
                />
                {`${SB_recipeMultilingueFields?.content?.Votes}: ${recipe?.votes.voteCount}`}
              </Link>
            </div>
            <div className={styles.details_recipeInfo}>
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 900,
                  color: "black",
                  marginBottom: 10,
                }}
              >
                {recipe?.title[language]}
              </p>
              <p className={styles.details_submittedCreatedBy}>
                {`${SB_recipeMultilingueFields?.content?.SubmittedBy} ${recipe?.submittedBy}`}
              </p>
              <div className={styles.details_twoColumns}>
                <div className={styles.details_prepCookTime}>
                  {`${SB_recipeMultilingueFields?.content?.Nutrition}: `}
                  <span>{`${Object.values(rddEvaluationsMap).find(rddEval => rddEval.value == recipe?.evaluations[0].name).name}`}</span>
                </div>
                <div className={styles.details_prepCookTime}>
                  {`${SB_recipeMultilingueFields?.content?.Difficulty}: `}
                  <span>{`${
                    difficultyLevels[recipe?.difficultyLevelId]?.name
                  }`}</span>
                </div>
              </div>
              <div className={styles.details_twoColumns}>
                <div className={styles.details_prepCookTime}>
                  <img src={PrepImage} />
                  <span>{`${recipe?.preparationTime} ${SB_recipeMultilingueFields?.content?.PreparationTime}`}</span>
                </div>
                <div className={styles.details_prepCookTime}>
                  <img src={CookImage} />
                  <span>
                    {" "}
                    {`${recipe?.cookingTime} ${SB_recipeMultilingueFields?.content?.CookingTime}`}
                  </span>
                </div>
              </div>
              <div className={styles.details_twoColumns}>
                <div>
                  <p className={styles.details_attributes}>
                    {`${SB_recipeMultilingueFields?.content?.Serves}: `}
                    <span>{`${recipe?.serves}`}</span>
                  </p>
                  <p className={styles.details_attributes}>
                    {`${SB_recipeMultilingueFields?.content?.Cuisine}: `}
                    {
                      <span>{` ${
                        cuisines[recipe.cuisineId]?.name ?? ""
                      }`}</span>
                    }
                  </p>
                  <p className={styles.details_attributes}>
                    {`${SB_recipeMultilingueFields?.content?.VegeterianType}: `}
                    <span>{`${
                      vegetarianTypes[recipe.vegetarianTypeId]?.name
                    }`}</span>
                  </p>
                </div>
                <div>
                  <>
                    <p className={styles.details_attributes}>
                      {`${SB_recipeMultilingueFields?.content?.ProteinComplementarity}: `}
                      <span>{`${
                        mealLevels[recipe?.qualities.proteinComplementarity]
                          ?.name
                      }`}</span>
                    </p>
                    <p className={styles.details_attributes}>
                      {`${SB_recipeMultilingueFields?.content?.IronAbsorption}: `}
                      <span>{`${
                        mealLevels[recipe?.qualities.ironAbsortion]?.name
                      }`}</span>
                    </p>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.details_actionsWrapper}>
            <div
              className={
                "pointer " +
                `${styles.details_actionsBtn} ${voteInfo ? styles.active : ""}`
              }
              onClick={handleVoting}
            >
              {voteInfo ? (
                <ThumbUpOffAltIcon
                  style={{ color: "white", fontSize: 22 }}
                ></ThumbUpOffAltIcon>
              ) : (
                <ThumbUpOffAltIcon
                  style={{ color: "#faa72d", fontSize: 22 }}
                ></ThumbUpOffAltIcon>
              )}
            </div>
            <div
              className={
                "pointer " +
                `${styles.details_actionsBtn} ${preferred ? styles.active : ""}`
              }
              onClick={handlePreference}
            >
              {preferred ? (
                <FaBookmark
                  className="pointer"
                  style={{
                    fontSize: 22,
                    stroke: "white",
                    strokeWidth: "25",
                    fill: "#faa72d",
                  }}
                  onClick={handlePreference}
                />
              ) : (
                <FiBookmark
                  className="pointer"
                  style={{ fontSize: 28, color: "orange", strokeWidth: "1" }}
                  onClick={handlePreference}
                />
              )}
            </div>

            <div
              className={
                "pointer " +
                `${styles.details_actionsBtn} ${addedMeal ? styles.active : ""}`
              }
              onClick={handleWeeklyPlan}
            >
              {addedMeal ? (
                <TbCalendarTime
                  style={{
                    fontSize: "26px",
                    color: "white",
                  }}
                ></TbCalendarTime>
              ) : (
                <TbCalendarTime
                  style={{
                    fontSize: "26px",
                    color: "#faa72d",
                  }}
                ></TbCalendarTime>
              )}
            </div>
          </div>
          <div className={styles.details_ingredientsAndGraphWrapper}>
            <div className={styles.details_ingredientsWrapper}>
              <div className="d-flex justify-content-between align-items-center">
                <p className={styles.details_ingredientsTitle}>
                  {SB_recipeMultilingueFields?.content?.Ingredients}
                </p>
              </div>
              <div className={styles.details_ingredientsWrapper}>
                {recipe?.ingredients
                  .sort((a, b) => a.position - b.position)
                  .map((ingredient, index) => (
                    <IngredientElementItem
                      key={index}
                      ingredient={ingredient}
                    />
                  ))}
              </div>
              <div>
                {recipe?.instructions.map((instructionStep, index) => (
                  <InstructionStepItem
                    key={index}
                    step={instructionStep.step}
                    instruction={instructionStep.text[language]}
                    color={"#FAA72D"}
                    stepLabel={SB_recipeMultilingueFields?.content?.Step}
                  />
                ))}
              </div>
            </div>
            <div className={styles.details_graphContainer}>
              {recipe?.quantityPerPerson && (
                <PieChart
                  data={recipe.quantityPerPerson}
                  lang={SB_recipeMultilingueFields?.content}
                />
              )}
            </div>
          </div>
        </div>
      </PageTemplate>

      <VotingMealDialog
        openDialog={votingOpen}
        onCloseHandler={() => handleClose()}
        title={SB_recipeMultilingueFields?.content?.Vote_Title}
        recipe={recipe}
      ></VotingMealDialog>

      <Dialog
        open={planOpen}
        onClose={() => setPlanOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <div style={{ marginTop: 30, textAlign: "center", width: "100%" }}>
          <Calendar
            onChangeMealTime={(date, mealTime) =>
              handleChangeMealTime(date, mealTime)
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            margin: "20px",
          }}
        >
          <Button
            variant="contained"
            fontColor="white"
            width={100}
            height={35}
            onClick={handleClosePlan}
          >
            Close
          </Button>
          <Button
            variant="contained"
            fontColor="white"
            width={100}
            height={35}
            onClick={handleApplyPlan}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default VegetarianFoodDetails;
