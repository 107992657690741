import React, { useEffect, useState } from "react";
import { useStoryblok } from "../../../services/storyblokService";
import styles from "../../../styles/HungryForInsights.module.css";
import ThreeColumns from "../../../components/ThreeColumns";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Breadcrums from "../../../components/Breadcrums";
import TopMenuBar from "components/TopMenuBar";
import Search from "../../../components/Search";
import PageTemplate from "../../../components/PageTemplate";
import { Pagination } from "../../../components";
import { useSelector } from "react-redux";

const HungryforInsights = (props) => {
  const ARTICLES_PER_PAGE = 6;
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    starts_with: "articles_folder",
    page: page,
    per_page: ARTICLES_PER_PAGE,
    sort_by: "",
  });

  const SB_articles = useStoryblok(null, { ...params });

  const SB_articlesSection = useStoryblok("articles-section");
  const SB_FiltersAndOrders = useStoryblok("filters-and-orders");

  const { language } = useSelector((state) => state.app);
  const [sortByIndex, setSortByIndex] = useState();

  useEffect(() => {
    if (params.sort_by !== "") {
      if (params.sort_by.includes("__i18n__")) {
        params.sort_by = params.sort_by.replace(/__i18n__.*:/, ":");
      }

      // Split the input string by ":"
      const parts = params.sort_by.split(":");

      // Combine the parts with "__i18n__" inserted before the colon ":"
      //filter doesnt work with __i18n__en because en is default language
      if (language !== "en") {
        const modifiedString = parts[0] + `__i18n__${language}:` + parts[1];
        setParams({ ...params, sort_by: modifiedString });
      }
    }
  }, [language]);
  const [showFilters, setShowFilters] = useState(true);
  const [activeTypeOfArticles, setActiveTypeOfArticles] = useState([]);

  const handleOrderByClick = (sortBy, sortByIndex) => {
    if (sortBy.includes("__i18n__")) {
      sortBy = sortBy.replace(/__i18n__.*:/, ":");
    }

    // Split the input string by ":"
    const parts = sortBy.split(":");

    // Combine the parts with "__i18n__" inserted before the colon ":"
    //filter doesnt work with __i18n__en because en is default language
    if (language !== "en") {
      sortBy = parts[0] + `__i18n__${language}:` + parts[1];
    }

    if (params.sort_by === sortBy) {
      setSortByIndex(undefined);
      setParams({ ...params, sort_by: "", page: 1 });
    } else {
      setSortByIndex(sortByIndex);
      setParams({ ...params, sort_by: sortBy, page: 1 });
    }
    setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setParams({ ...params, page: page });
  };

  const handleSearchChange = (keyword) => {
    setParams({ ...params, search_term: keyword, page: 1 });
    setPage(1);
  };

  const handleTypeOfArticleClick = (event, typeOfArticle) => {
    let res = [];
    if (event.target.checked) {
      res.push(typeOfArticle);
    } else {
      res = activeTypeOfArticles.filter((element) => element !== typeOfArticle);
    }
    setActiveTypeOfArticles(res);
    const filters = res.toString();
    if (filters === "") {
      delete params.filter_query;
    } else {
      setParams({
        ...params,
        page: 1,
        filter_query: { "Type_of.0.Type.0": { in: filters } },
      });
      setPage(1);
    }
  };

  return (
    <PageTemplate
      backgroundImgSrc={`${SB_articlesSection?.content?.Back_image[0]?.filename}`}
      title={SB_articlesSection?.content?.Title}
      subtitle={SB_articlesSection?.content?.Body}
    >
      <div style={{ padding: "0px 45px" }}>
        <div className={styles.hungryForInsights_breadcrums}>
          <Breadcrums
            data={[
              { link: true, href: "/home", name: "Home" },
              {
                text: true,
                name: SB_articlesSection?.content?.Title,
              },
            ]}
          ></Breadcrums>
        </div>
        <TopMenuBar
          selectedSortBy={sortByIndex}
          showFilters={showFilters}
          onClickFiltersBtn={() => setShowFilters(!showFilters)}
          SB_FiltersAndOrders={SB_FiltersAndOrders}
          menuItems={[
            {
              name: SB_FiltersAndOrders?.content?.Order_by_newer_date,
              applyFilter: () => handleOrderByClick("content.Date:desc", 0),
            },
            {
              name: SB_FiltersAndOrders?.content?.Order_by_older_date,
              applyFilter: () => handleOrderByClick("content.Date:asc", 1),
            },
            {
              name: SB_FiltersAndOrders?.content?.Order_by_title,
              applyFilter: () => handleOrderByClick(`content.Title:asc`, 2),
            },
            {
              name: SB_FiltersAndOrders?.content?.Order_by_type,
              applyFilter: () =>
                handleOrderByClick("content.Type_of.0.Type:asc", 3),
            },
          ]}
        ></TopMenuBar>
        <div className={styles.hungryForInsights_content_container}>
          <div
            className={`${
              styles.hungryForInsights_content_container_leftMenu
            } ${showFilters ? styles.showFilters : ""}`}
          >
            <Search
              placeholder={SB_FiltersAndOrders?.content?.Example}
              onChange={handleSearchChange}
            ></Search>
            <div
              className={
                styles.hungryForInsights_content_container_typeOfArticle
              }
            >
              <p
                className={
                  styles.hungryForInsights_content_container_typeOfArticle_title
                }
              >
                {SB_articlesSection?.content?.Type_article}
              </p>
              <FormGroup>
                {SB_articlesSection?.content?.Type[0]?.Type?.map(
                  (element, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            sx={{
                              color: "#B8BABD",
                            }}
                            onClick={(e) =>
                              handleTypeOfArticleClick(e, element)
                            }
                          />
                        }
                        label={element}
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 16,
                            color: "#6B6C72",
                          },
                        }}
                      />
                    );
                  }
                )}
              </FormGroup>
            </div>
          </div>

          <div className={styles.hungryForInsights_content_cards}>
            {SB_articles?.stories?.map((element) => {
              return (
                <ThreeColumns
                  key={element.content._uid}
                  story={element}
                  articleSection={SB_articlesSection?.content}
                />
              );
            })}
          </div>
        </div>

        {SB_articles?.pages > 1 && (
          <div className={styles.hungryForInsights_pagination_wrapper}>
            <Pagination
              numPages={SB_articles?.pages}
              page={page}
              onChangePage={(e, page) => handlePageChange(page)}
            />
          </div>
        )}
      </div>
    </PageTemplate>
  );
};

export default HungryforInsights;
