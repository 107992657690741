import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchImage from "../assets/image/search.svg";

const Search = ({ value, placeholder, onChange, style }) => {
  const customStyle = {
    m: 0,
    width: "25ch",
    "& .MuiInputBase-root": {
      borderRadius: "20px",
      boxShadow: "0px 0px 15px 0px #00000036",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E3E7",
      },
      "&:hover fieldset": {
        borderColor: "#faa72d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#faa72d",
      },
    },
    ...style,
  };

  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{ ...customStyle }}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={SearchImage} alt="" loading="lazy" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;