import React, { forwardRef, useState } from "react";

import {
  HiOutlineEye,
  HiOutlineEyeOff,
} from "react-icons/hi";

const LineEdit = forwardRef((props, ref) => {
  const {
    placeholder,
    type,
    name,
    style,
    value,
    readOnly,
    onChange,
    editable,
  } = props;

  const [edit, setOff] = useState(false);

  const handleClick = () => {
    setOff(!edit);

    var x = ref.current;
    console.log(x);
    if (edit) {
      x.type = "password";
    } else {
      x.type = "text";
    }
  };

  return (
    <div className="sign-edit-container" style={style}>
      <input
        ref={ref}
        type={type}
        name={name}
        className="custom-input"
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        onChange={(val) => onChange(val.target.value)}
      />
      {editable ? (
        edit ? (
          <HiOutlineEye className="pointer" size={20} onClick={handleClick} />
        ) : (
          <HiOutlineEyeOff
            className="pointer"
            size={20}
            onClick={handleClick}
          />
        )
      ) : (
        ""
      )}
    </div>
  );
});

export default LineEdit;
