import React, { memo } from "react";
import { useStoryblok } from "../services/storyblokService";

const Logo = (props) => {
  const SB_logo = useStoryblok("logo");
  return (
    <img
      src={SB_logo?.content?.Logo?.filename}
      style={{ width: `${props.width}` }}
      alt={"Logo"}
      loading={"lazy"}
    ></img>
  );
};

export default memo(Logo);
