import React from "react";
import styles from "./PageTemplate.module.css";

const PageTemplate = ({ children, backgroundImgSrc, title, subtitle }) => {
  return (
    <>
      <div className={styles.pageTemplate_page_container}>
        <div className={styles.pageTemplate_gradiente}></div>
        {backgroundImgSrc && (
          <img
            className={styles.pageTemplate_imgBackground}
            src={backgroundImgSrc}
            alt={""}
            loading={"lazy"}
          ></img>
        )}
        {title && (
          <h1 className={`${styles.pageTemplate_title} orangeLineUnderText`}>
            {title}
          </h1>
        )}
        {subtitle && (
          <h2 className={styles.pageTemplate_subtitle}>{subtitle}</h2>
        )}
        <div className={styles.pageTemplate_container}>{children}</div>
      </div>
    </>
  );
};

export default PageTemplate;
