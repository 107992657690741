export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_LOADING = 'LOGOUT_LOADING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const REGISTER_LOADING = 'REGISTER_LOADING'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const AUTH_CHECKING = 'AUTH_CHECKING'
export const AUTH_CHECKED = 'AUTH_CHECKED'