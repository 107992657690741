import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { Button, LineEdit } from "../../../components";
import { history } from "../../../store/history";
import { Footer, Topbar } from "../../../layouts/Main/components";
import { useStoryblok } from "../../../services/storyblokService";
import PageTemplate from "../../../components/PageTemplate";

const ConfirmUser = (props) => {
  const [code, setCode] = useState("");

  const SB_confirmUser = useStoryblok("confirm-user");

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      const email = props.location.state.email;
      await Auth.confirmSignUp(email, code);
      history.push("/sign-in");
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  };

  return (
    <>
      <Topbar></Topbar>
      <PageTemplate>
        <div
          style={{ minHeight: "70vh", marginBottom: "60px", padding: "0 20px" }}
        >
          <div className="text-center" style={{ marginTop: 50 }}>
            <h1 className="h2">{SB_confirmUser?.content?.Title}</h1>
            <p className="lead text-center" style={{}}>
              {SB_confirmUser?.content?.Mesg}
            </p>
          </div>
          <div style={{ maxWidth: 400, margin: "auto", textAlign: "center" }}>
            <form onSubmit={handleConfirm} style={{ marginTop: 110 }}>
              <LineEdit
                type="text"
                name="code"
                placeholder={SB_confirmUser?.content?.Code}
                value={code}
                onChange={(val) => setCode(val)}
              />
              <Button
                variant="contained"
                bgColor="primary"
                fontColor="white"
                width={120}
                height={36}
                style={{ margin: "auto" }}
                onClick={handleConfirm}
              >
                {SB_confirmUser?.content?.Confirm}
              </Button>
            </form>
          </div>
        </div>
      </PageTemplate>
      <Footer></Footer>
    </>
  );
};

export default ConfirmUser;
