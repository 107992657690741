import { Auth } from "aws-amplify";
import * as types from './actionTypes'
import { ApiService } from '../../services/apiService';
import { showToastr } from '../../services/themeService';
import { history } from '../history';
import {createDebouncedAction } from "../../utils/debouncer";

export const loginUser = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: types.LOGIN_LOADING });

    try {
      const cognitoUser = await Auth.signIn(email, password);
      const user = {
        id: cognitoUser.signInUserSession.idToken.payload.id,
        token: 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken,
        profileCreated: cognitoUser.signInUserSession.idToken.payload.profileCreated
      };

      dispatch({ type: types.LOGIN_SUCCESS, user });

      if (user.profileCreated === 'true') {
        dispatch(fetchProfile(user.id));
        history.push('/home');
      } else {
        console.log('Profile not created. Redirecting to wizard');
        history.push('/preferences/wizard');
      }
    } catch (error) {
      console.error('Error signing in', error);
      showToastr('error', 'Log in Error', error.message);
      dispatch({ type: types.LOGIN_ERROR, error: error.message });
    }
  };
};

// Fetch profile action
export const fetchProfile = (userId) => {
  return async (dispatch, getState) => {
    dispatch({ type: types.PROFILE_LOADING });

    try {
      const profile = await ApiService.getProfile(userId);
      dispatch({ type: types.PROFILE_SUCCESS, profile });

      // Update filter options with profile data
      dispatch({
        type: types.FILTER_SUCCESS,
        filter: {
          ...getState().session.filterOptions,
          cuisines: profile.cuisines ? [...profile.cuisines] : [],
          vegetarianTypes: profile.vegetarianTypes ? [...profile.vegetarianTypes] : [],
        }
      });
    } catch (error) {
      console.error('Profile fetch error:', error);
      dispatch({ type: types.PROFILE_ERROR, error: error.message });
    }
  };
};

export const federatedLoginUser = (cognitoUser) => {
  return (dispatch) => {
    dispatch(loginLoading());

    let user = {};
    user.id = cognitoUser.signInUserSession.idToken.payload.id;
    user.token = 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken;
    user.profileCreated = cognitoUser.signInUserSession.idToken.payload.profileCreated;

    console.log('user: ', user);

    dispatch(loginSuccess(user));

    dispatch(profileLoading());

    ApiService.getProfile(user.id)
    .then(data => {
      console.log('profile: ', data);
      dispatch(profileSuccess(data));

      if (user.profileCreated === 'true') {
        history.push('/home');
      } else {
        history.push('/preferences/wizard');
      }
    })
    .catch((error) => {
      console.log('profile error: ', error);
      showToastr('error', 'Profile Error', error.message);
      dispatch(profileError(error.message));
    });
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    dispatch(logoutLoading());

    Auth.signOut()
      .then(() => {
        console.log('logout success');
        dispatch(logoutSuccess());
        history.push('/sign-in');
      })
      .catch(error => {
        console.log('error signing out: ', error);
      });
  }
}

export const registerUser = (email, password) => {
  return (dispatch) => {
    dispatch(registerLoading());

    Auth.signUp({ username: email, password })
      .then(cognitoUser => {
        console.log('register user: ', cognitoUser);
        dispatch(registerSuccess());

        history.push('/confirm-user', { email });
      })
      .catch(error => {
        console.log('error signing up', error);
        showToastr('error', 'Log in Error', error.message);
        dispatch(registerError(error.message));
      });
  }
}

export const getProfile = () => {
  return (dispatch, getState) => {
    const { session } = getState();

    dispatch(profileLoading())
    
    if (typeof session?.user?.id === "undefined"){
      console.log('User id not found. No profile to fetch.');
      dispatch(profileError(""));
    } else {
      ApiService.getProfile(session.user.id)
      .then(data => {
        console.log('profile: ', data);
        dispatch(profileSuccess(data))
      })
      .catch((error) => {
        console.log('profile error: ', error);
        dispatch(profileError(error.message));
      });
    }
  }
}

export const updateProfile = (profile) => {
  return (dispatch, getState) => {
    const { session } = getState();

    dispatch(profileLoading());

    console.log('update profile: ', JSON.stringify(profile));
    ApiService.updateProfile(session.user.id, profile)
      .then(data => {
        console.log('update profile: ', data);
        dispatch(profileSuccess(data))
        console.log('Checking auth state after profile update');
        dispatch(checkAuthState())
      })
      .catch((error) => {
        console.error('update profile error: ', error);
        dispatch(profileError(error.message))
      });
    console.log('update profile end');
  }
}

export const checkAuthState = () => {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CHECKING });
    console.log('Checking auth state');
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const user = {
        id: cognitoUser.signInUserSession.idToken.payload.id,
        token: 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken,
        profileCreated: cognitoUser.signInUserSession.idToken.payload.profileCreated
      };
      console.log('Current user from cognito: ', JSON.stringify(user));

      dispatch({ type: types.LOGIN_SUCCESS, user });

      // This verification is disabled since the profileCreated parameter is not set correctly.
      /*if (user.profileCreated === 'true') {
        dispatch(fetchProfile(user.id));
      } else {
        console.log('Profile not created. Redirecting to wizard');
        history.push('/preferences/wizard');
      }
       */

      dispatch(fetchProfile(user.id));
      dispatch({ type: types.AUTH_CHECKED, isAuthenticated: true });
    } catch (error) {
      console.log('No current user', error);
      dispatch({ type: types.AUTH_CHECKED, isAuthenticated: false });
    }
  };
};

export const updateFilter = (filter) => {
  return (dispatch) => {

    dispatch(filterSuccess(filter));
  }
}

// export const refreshToken = () => {
//   return (dispatch) => {
//     dispatch(loginLoading())

//     Auth.currentSession()
//       .then(cognitoUser => {
//         console.log(cognitoUser);
//         let user = {};
//         user.id = cognitoUser.signInUserSession.idToken.payload.id;
//         user.token = 'Bearer ' + cognitoUser.signInUserSession.idToken.jwtToken;
//         user.profileCreated = cognitoUser.signInUserSession.idToken.payload.profileCreated;

//         console.log('user: ', user);

//         dispatch(loginSuccess(user));
//       })
//       .catch(error => {
//         console.log('error signing in', error);
//         showToastr('error', 'Log in Error', error.message);
//         dispatch(loginError(error));
//       });
//   }
// }

export const loginLoading = createDebouncedAction(() => ({
  type: types.LOGIN_LOADING
}), 200)

export const loginSuccess = createDebouncedAction(
  (user) => ({
  type: types.LOGIN_SUCCESS,
  user
})
, 500);

export const loginError = error => ({
  type: types.LOGIN_ERROR,
  error
});

// Logout actions - These should not be debounced
export const logoutLoading = () => ({
  type: types.LOGOUT_LOADING
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const logoutError = error => ({
  type: types.LOGOUT_ERROR,
  error
});

// Register actions - These should not be debounced
export const registerLoading = () => ({
  type: types.REGISTER_LOADING
});

export const registerSuccess = () => ({
  type: types.REGISTER_SUCCESS
});

export const registerError = error => ({
  type: types.REGISTER_ERROR,
  error
});

// Profile actions - Loading and Error should not be debounced, but Success could be
export const profileLoading = () => ({
  type: types.PROFILE_LOADING
});

export const profileSuccess = createDebouncedAction(
  (profile) => ({
    type: types.PROFILE_SUCCESS,
    profile,
  }),
  500  // 500ms debounce time
);

export const profileError = error => ({
  type: types.PROFILE_ERROR,
  error
});

// Filter action - This could benefit from debouncing
export const filterSuccess = createDebouncedAction(
  (filter) => ({
    type: types.FILTER_SUCCESS,
    filter,
  }),
  300  // 300ms debounce time
);

export const authChecking = () => ({
  type: types.AUTH_CHECKING
});

export const authChecked = () => ({
  type: types.AUTH_CHECKED
});