import React, { useEffect } from "react";
import styles from "../../../styles/ArticlePage.module.css";
import { useStoryblok } from "services/storyblokService";
import { useParams } from "react-router-dom";
import { RichText } from "utils/RichText";
import BookImage from "../../../assets/image/book.svg";
import ShareImage from "../../../assets/image/share.svg";
import Breadcrums from "../../../components/Breadcrums";
import PageTemplate from "../../../components/PageTemplate";

const ArticlePage = () => {
  const SB_articlesSection = useStoryblok("articles-section");
  let { slug } = useParams();
  const SB_article = useStoryblok(`articles_folder/${slug}`);

  return (
    <>
      <PageTemplate
        backgroundImgSrc={`${SB_articlesSection?.content?.Back_image[0]?.filename}`}
      >
        <div className={styles.articlePage_card}>
          <img
            className={styles.articlePage_card_img}
            src={`${SB_article?.content?.Image?.filename}`}
          ></img>
          <div className={styles.articlePage_card_bottom}>
            <div className={styles.articlePage_breadcrums}>
              <Breadcrums
                data={[
                  { link: true, href: "/home", name: "Home" },
                  {
                    link: true,
                    href: "/hungry-for-insights",
                    name: SB_articlesSection?.content?.Title,
                  },
                  {
                    text: true,
                    name: SB_article?.content?.Title,
                  },
                ]}
              ></Breadcrums>
            </div>
            <div className={styles.articlePage_card_header}>
              <div className={styles.articlePage_card_header_left}>
                <h1 className={styles.articlePage_card_header_title}>
                  {SB_article?.content?.Title}
                </h1>
                <p className={styles.articlePage_card_header_submited}>
                  {SB_articlesSection?.content?.Label_Author}{" "}
                  {SB_article?.content?.Author}
                </p>
                <div className={styles.articlePage_card_header_readingTime}>
                  <img src={BookImage}></img>
                  <p>
                    {SB_article?.content?.Reading_Time}
                    {SB_articlesSection?.content?.Label_read_time}
                  </p>
                </div>
              </div>
              <div className={styles.articlePage_card_header_right}>
                <img src={ShareImage}></img>
              </div>
            </div>
            {RichText(SB_article?.content?.Body)}
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default ArticlePage;
