import React from "react";
import { Dialog as MUIDialog } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{
        margin: 0,
        padding: 1,
      }}
      // className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: "palette.grey[500]",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const Dialog = (props) => {
  const { open, onClose, title, actions, children, maxWidth, fullWidth } =
    props;

  return (
    <MUIDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth ?? "sm"}
      open={open}
      onClose={onClose}
      disableScrollLock
      sx={{
        "& .MuiPaper-root": {
          padding: "0px 50px",
          maxHeight: "calc(100% - 120px)",
          transform: "translate(0px, 25px)",
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>

      <MuiDialogContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {children}
      </MuiDialogContent>
      {actions ? (
        <MuiDialogActions
          sx={{
            margin: 0,
            padding: 1,
          }}
        >
          <Button autoFocus onClick={onClose} color="primary">
            Save changes
          </Button>
        </MuiDialogActions>
      ) : null}
    </MUIDialog>
  );
};

export default Dialog;
