import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { showToastr } from "../../../services/themeService";
import { Button, LineEdit } from "../../../components";
import { history } from "../../../store/history";
import { Footer, Topbar } from "../../../layouts/Main/components";
import Roma from "../../../assets/image/RomaFruta.png";
import DotsImage from "../../../assets/image/background-dots3.png";
import { useStoryblok } from "../../../services/storyblokService";

const ResetPassword = (props) => {
  const SB_forgotPassword = useStoryblok("forgotpassword");

  const [code, setCode] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    if (pass !== confirmPass) {
      showToastr(
        "error",
        "Invalid Request",
        `${SB_forgotPassword?.content?.Mesg_Passw_Rejected}`,
        { timeOut: 0, removeOnHover: false, removeOnHoverTimeOut: 0 }
      );

      return;
    }

    try {
      const email = props.location.state.email;
      const data = await Auth.forgotPasswordSubmit(email, code, pass);
      history.push("/sign-in");
    } catch (error) {
      showToastr("error", error.name, error.message, {
        timeOut: 0,
        removeOnHover: false,
        removeOnHoverTimeOut: 0,
      });
    }
  };

  return (
    <>
      <div className="row" style={{ height: "100%", width: "100%", margin: 0 }}>
        <div
          className="col-md-6 sign-img-container"
          style={{
            maxWidth: 960,
            padding: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Roma} style={{ width: "100%" }}></img>
        </div>
        <div className="col-md-6 sign-auth-form">
          <div
            style={{
              backgroundImage: `url(${DotsImage})`,
              height: "100%",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div className="text-center mt-4">
              <h1 className="h2">
                {SB_forgotPassword?.content?.Reset_password_title}
              </h1>
              <p className="lead text-center">
                {SB_forgotPassword?.content?.Reset_Password_Mesg}
              </p>
            </div>

            <div
              style={{
                maxWidth: 400,
                margin: "auto",
                marginTop: 30,
                textAlign: "center",
              }}
            >
              <form className="m-sm-4" onSubmit={handleReset}>
                <LineEdit
                  type="text"
                  name="code"
                  placeholder={SB_forgotPassword?.content?.Enter_Code_Mesg}
                  value={code}
                  onChange={(val) => setCode(val)}
                />
                <LineEdit
                  type="password"
                  name="pass"
                  placeholder={SB_forgotPassword?.content?.Enter_password_mesg}
                  value={pass}
                  onChange={(val) => setPass(val)}
                />
                <LineEdit
                  type="password"
                  name="confirm-pass"
                  placeholder={SB_forgotPassword?.content?.Repeat_Password_Mesg}
                  value={confirmPass}
                  onChange={(val) => setConfirmPass(val)}
                />
                <Button
                  variant="contained"
                  bgColor="primary"
                  fontColor="white"
                  onClick={handleReset}
                  maxWidth="200px"
                  height={50}
                  style={{
                    background:
                      "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
                    boxShadow: "1px 9px 31px rgba(0, 0, 0, 0.25)",
                    bordeRadius: "25.5px",
                    padding: "0 20px",
                  }}
                >
                  {SB_forgotPassword?.content?.Send_NewPassword}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
