import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "../../../../components";
import { logoutUser, updateProfile } from "../../../../store/session";

import CalendarIcon from "../../../../assets/image/CalendarioIcon.png";
import SavedIcon from "../../../../assets/image/GuardadoIcon.png";
import ProfileIcon from "../../../../assets/image/ProfileIcon.png";
import RecipesIcon from "../../../../assets/image/ReceitasIcon.png";
import CloseIcon from "../../../../assets/image/close.png";
import { selectLanguage } from "../../../../store/app";

import chili from "../../../../assets/image/malagueta.png";
import arrow from "../../../../assets/image/dropArrow.png";
import { useStoryblok } from "../../../../services/storyblokService";
import ClickOutside from "../../../../hooks/useClickOutside";
import Logo from "../../../../components/Logo.js";
import {
  setLanguages,
  setLogoutMsg,
  setWeb_Menu,
} from "../../../../store/storyblok/actions";

const LangDropdownList = (props) => {
  const headerStyle = {
    position: "absolute",
    left: "auto",
    fontFamily: "Roboto",
    fontSize: "20px",
    boxShadow: "0px 7px 9px rgba(0, 0, 0, 0.1)",
    zIndex: 9999,
    padding: "0.5rem 30px 0.25rem 10px",
    listStyle: "none",
    lineHeight: "23.44px",
    backgroundColor: "#fff",
    borderRadius: "0px",
    display: "block",
  };

  const getStyle = function () {
    headerStyle["display"] = props.dropdown ? "block" : "none";

    return headerStyle;
  };

  function handleClick(event) {
    props.onChildClick(event);
  }

  return (
    <ul style={getStyle()}>
      {props.langs.map((element) => {
        return (
          <li
            key={element.Name}
            onClick={() => handleClick(element)}
            style={{
              marginBottom: "10px",
              color:
                props.selectedLang?.Name === element.Name
                  ? "#07122E"
                  : "#B8BABD",
              fontSize: "20px",
              lineHeight: "23px",
              fontWeight: 400,
              fontFamily: "Roboto",
              fontStyle: "normal",
            }}
          >
            {" "}
            {element.Name}{" "}
          </li>
        );
      })}
    </ul>
  );
};

const LangDropdown = () => {
  const [dropdown, setDropdown] = useState(false);
  const language = useSelector((state) => state.app.language);
  const languages = useSelector((state) => state.app.languages);
  const profile = useSelector((state) => state.session.profile);
  const redux_languages = useSelector((state) => state.storyBlok.languages);
  const [availableLangs, setAvailableLangs] = useState([]);
  const [selectedLang, setSelectedLang] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (redux_languages && redux_languages.length > 0) {
      let aux = [];
      redux_languages.forEach((element) => {
        if (element.Enabled) aux.push({ ...element });
      });
      setAvailableLangs(aux);
      setSelectedLang(aux.find((l) => l.Id === language));
    }
  }, [redux_languages, language]);

  const onClickOutsideHandler = () => {
    if (dropdown) setDropdown(!dropdown);
  };

  const onClickHandler = () => {
    setDropdown(!dropdown);
  };

  function onLangSelectedHandler(lang) {
    setDropdown(false);
    dispatch(selectLanguage(lang?.Id, true));
    if (profile) {
      for (const element in languages) {
        if (languages[element].code.toLowerCase() === lang?.Id) {
          profile.langId = parseInt(element);
          //have to delete image because off error on backend
          delete profile.image;
          dispatch(updateProfile(profile));
        }
      }
    }
  }

  return (
    <ClickOutside handler={() => onClickOutsideHandler()}>
      <span style={{ cursor: "pointer" }} onClick={() => onClickHandler()}>
        <img
          src={selectedLang?.Flag?.filename}
          style={{ width: "33px", height: "25px" }}
          alt="Country flag"
          loading={"lazy"}
        />
        <img
          src={arrow}
          style={{ width: "12px", height: "8px", marginLeft: "24px" }}
          alt="Down arrow"
          loading={"lazy"}
        />
        <LangDropdownList
          langs={availableLangs}
          onChildClick={onLangSelectedHandler}
          dropdown={dropdown}
          selectedLang={selectedLang}
        />
      </span>
    </ClickOutside>
  );
};

const ConfirmPopUp = ({ title, text, img, yesClicked, noClicked }) => {
  const redux_logout_msg = useSelector((state) => state.storyBlok.logoutMsg);

  const popUpStyle = {
    width: "600px",
    height: "235px",
    boxShadow: "-2px 4px 6px rgba(40, 40, 40, 0.2)",
    backgroundColor: "#fff",
    borderRadius: "15px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const btnStyle = {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    width: "30px",
    height: "30px",
    borderRadius: "20px",
    lineHeight: "30px",
    textAlign: "center",
    fontSize: "18px",
  };

  const titleStyle = {
    textAlign: "center",
    marginTop: "20px",
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "35px",
  };

  const textStyle = {
    textAlign: "center",
    marginTop: "40px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
  };

  return (
    <div style={popUpStyle}>
      <div style={btnStyle} className={"orangeBtn"} onClick={noClicked}>
        {" "}
        X{" "}
      </div>

      <div
        style={{
          width: "50%",
          height: "100%",
          float: "left",
          textAlign: "center",
        }}
      >
        <img
          src={img}
          style={{ padding: "30px", width: "100%", height: "100%" }}
          alt={""}
          loading={"lazy"}
        />
      </div>
      <div style={{ width: "50%", height: "100%", float: "right" }}>
        <div style={titleStyle}> {title} </div>
        <div style={textStyle}> {text} </div>
        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <Button
            variant="contained"
            bgColor="white"
            fontColor="#FAA72D"
            onClick={yesClicked}
            width="111px!important"
            height="42px!important"
            style={{
              display: "inline !important",
              marginRight: "15px!important",
              color: "#FAA72D !important",
              background: "white !important",
              boxShadow: "1px 9px 25px rgba(0, 0, 0, 0.15) !important",
              borderRadius: "25.5px !important",
            }}
          >
            {redux_logout_msg?.Yes}
          </Button>
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            onClick={noClicked}
            width="111px!important"
            height="42px!important"
            style={{
              display: "inline !important",
              color: "white!important",
              marginLeft: "15px!important",
              background:
                "linear-gradient(180deg, #FAA72D 0%, #F28231 100%) !important",
              boxShadow: "1px 9px 31px rgba(0, 0, 0, 0.25) !important",
              borderRadius: "25.5px !important",
            }}
          >
            {redux_logout_msg?.No}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ submenus, dropdown }) => {
  const redux_webMenu = useSelector((state) => state.storyBlok.web_menu);
  const redux_logout_msg = useSelector((state) => state.storyBlok.logoutMsg);

  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(logoutUser());
  };

  const [showPopup, setShowPopup] = useState(false);

  const yesClicked = (e) => {
    e.stopPropagation();
    setShowPopup(false)
    handleSignOut();
  };

  const showConfirmDlg = (e, newVal) => {
    e.stopPropagation();
    setShowPopup(newVal);
  };

  useEffect(() => {
    if (!dropdown) {
      setShowPopup(false);
    }
  }, [dropdown]);

  const slug = window.location.pathname;
  const getClass = (x) => {
    const baseClass = "dropdownItem ";
    return baseClass + (x === slug ? "selected" : "");
  };

  return (
    <ul className={`dropdownHeader ${dropdown ? "show" : ""}`}>
      {submenus.map((x) => (
        <li className="menu-items" >
          {x.logout && showPopup ? (
            <ConfirmPopUp
              title={redux_logout_msg?.Title}
              text={redux_logout_msg?.Question}
              img={chili}
              yesClicked={(event) => yesClicked(event)}
              noClicked={(event) => showConfirmDlg(event, false)}
            />
          ) : (
            ""
          )}
          {x.logout ? (
            <Button
              onClick={(event) => showConfirmDlg(event, true)}
              className="header-logout dropdownItem"
              style={{
                textAlign: " right",
                width: "100%",
                fontFamily: "Roboto, sans-serif",
                fontSize: "19px",
                justifyContent: "right",
              }}
            >
              <span style={{ marginRight: "2vw" }}>
                {redux_webMenu?.Logout}
              </span>
            </Button>
          ) : (
            <Link
              to={x.url}
              className={getClass(x.url)}
              style={{ textAlign: "right", marginRight: "2vw" }}
              key={x.url}
            >
              {x.title}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};


const NavBarItem = ({
  style,
  image,
  submenus,
  tooltip,
  href,
  onWizardPage,
}) => {
  const [dropdown, setDropdown] = useState(false);

  const onClickHandler = (e) => {
    if (submenus.length > 0 && !onWizardPage) {
      e.stopPropagation();
      setDropdown(!dropdown);
    }
  };

  const onClickOutsideHandler = () => {
    if (dropdown) {
      setDropdown(false);
    }
  };

  return (
    <ClickOutside handler={() => onClickOutsideHandler()}>
      <li style={style}>
        <button
          style={{background: 'transparent', border: 'none', padding: 0, cursor: 'pointer'}}
          onClick={(e) => onClickHandler(e)}
          aria-expanded={dropdown ? "true" : "false"}>
          {submenus.length === 0 ? (
            <div>
              {onWizardPage ? (
                <>
                  <img
                    src={image}
                    className="navBarButton"
                    style={{ width: "30px", height: "30px" }}
                    alt={""}
                    loading={"lazy"}
                  />
                </>
              ) : (
                <Link to={href ? href : "/home"} className="tooltipContainer">
                  <span className="tooltipTex">{tooltip}</span>
                  <img
                    src={image}
                    className="navBarButton"
                    style={{ width: "30px", height: "30px" }}
                    alt={"Home"}
                    loading={"lazy"}
                  />
                </Link>
              )}
            </div>
          ) : (
            <div style={{ cursor: "pointer" }}>
              <img
                src={dropdown ? CloseIcon : image}
                className="navBarButton"
                style={{ width: "30px", height: "30px" }}
                alt={"Close"}
                loading={"lazy"}
              />
              <Dropdown submenus={submenus} dropdown={dropdown} />
            </div>
          )}
        </button>
      </li>
    </ClickOutside>
  );
};

const NavBar = () => {
  const redux_webMenu = useSelector((state) => state.storyBlok.web_menu);
  const { isAuthenticated, profile } = useSelector((state) => state.session);
  const [submenus, setSubmenus] = useState([]);
  const location = useLocation();

  const onWizardPage = location.pathname.includes("/preferences/wizard");

  const headerStyle = {
    width: "100%",
    height: "60px",
    background: "#EFF5E9",
    boxShadow: "0px 13px 18px rgba(55, 55, 55, 0.2)",
    position: "fixed",
    zIndex: 9999,
    top: 0,
  };

  useEffect(() => {
    if (isAuthenticated && profile) {
      setSubmenus([
        { title: redux_webMenu?.Profile, url: "/preferences/edit" },
        { title: redux_webMenu?.Definitions, url: "/definitions" },
        {
          title: redux_webMenu?.Articles_section,
          url: "/hungry-for-insights",
        },
        { title: redux_webMenu?.How_it_works, url: "/how-it-works" },
        { title: redux_webMenu?.About, url: "/about-us" },
        {
          title: redux_webMenu?.Terms_conditions,
          url: "/terms-and-conditions",
        },
        { title: redux_webMenu?.Logout, url: "", logout: true },
      ]);
    } else {
      setSubmenus([
        { title: "Login", url: "/sign-in" },
        {
          title: redux_webMenu?.Articles_section,
          url: "/hungry-for-insights",
        },
        { title: redux_webMenu?.How_it_works, url: "/how-it-works" },
        { title: redux_webMenu?.About, url: "/about-us" },
        {
          title: redux_webMenu?.Terms_conditions,
          url: "/terms-and-conditions",
        },
      ]);
    }
  }, [isAuthenticated, profile, redux_webMenu]);

  return (
    <div style={headerStyle}>
      <span
        className="align-items-center justify-content-between"
        style={{
          maxWidth: 1100,
          margin: "auto",
          position: "absolute",
          left: "5vw",
          top: "25%",
        }}
      >
        <LangDropdown />
      </span>
      <span
        style={{
          position: "absolute",
          width: "50%",
          left: "25vw",
          textAlign: "center",
          top: "15%",
        }}
      >
        {onWizardPage ? (
          <Logo width={"60px"}></Logo>
        ) : (
          <Link to="/home" style={{ color: "#6B6C72", textDecoration: "none" }}>
            <Logo width={"60px"}></Logo>
          </Link>
        )}
      </span>
      <span
        className="align-items-center justify-content-between"
        style={{
          maxWidth: 1100,
          margin: "auto",
          position: "absolute",
          right: 0,
          top: "25%",
        }}
      >
        {isAuthenticated && profile ? (
          <ul className="nav-bar">
            <NavBarItem
              image={CalendarIcon}
              tooltip={redux_webMenu?.Top_weekly_plan}
              style={{ margin: "0px 12px" }}
              submenus={[]}
              href={"/weekly-plans"}
              onWizardPage={onWizardPage}
            />
            <NavBarItem
              image={SavedIcon}
              tooltip={redux_webMenu?.Top_my_list}
              style={{ margin: "0px 12px" }}
              submenus={[]}
              href={"/preferences/list"}
              onWizardPage={onWizardPage}
            />
            <NavBarItem
              image={RecipesIcon}
              tooltip={redux_webMenu?.Top_recipes_list}
              style={{ margin: "0px 12px" }}
              submenus={[]}
              href={"/vegetarian-foods"}
              onWizardPage={onWizardPage}
            />
            <NavBarItem
              image={ProfileIcon}
              tooltip={redux_webMenu?.Articles_section}
              style={{ margin: "0px 12px" }}
              submenus={submenus}
              href={""}
              onWizardPage={onWizardPage}
            />
          </ul>
        ) : (
          <ul className="nav-bar">
            <NavBarItem
              image={ProfileIcon}
              tooltip={""}
              style={{ margin: "0px 12px" }}
              submenus={submenus}
              href={""}
              onWizardPage={onWizardPage}
            />
          </ul>
        )}
      </span>
    </div>
  );
};

const Topbar = () => {
  const dispatch = useDispatch();
  const SB_logoutMsg = useStoryblok("logout-mesg");
  const SB_webMenu = useStoryblok("web-menu");
  const SB_languages = useStoryblok("languages");

  const { languages, language } = useSelector((state) => state.app);
  const { profile } = useSelector((state) => state.session);

  useEffect(() => {
    let defaultLang;

    if (profile) {
      if (profile.langId && languages) {
        defaultLang = languages[profile.langId].code.toLowerCase();
      }
    } else {
      if (language) {
        defaultLang = language;
      } else if (
        SB_languages &&
        SB_languages.content &&
        SB_languages.content.Language.length > 0
      ) {
        defaultLang = SB_languages.content.Language.find(
          (element) => element.Default === true
        ).Id.toLowerCase();
      }
    }
    if (defaultLang) dispatch(selectLanguage(defaultLang));
  }, [SB_languages, profile, languages, language, dispatch]);

  useEffect(() => {
    if (SB_logoutMsg && SB_logoutMsg.content) {
      dispatch(setLogoutMsg(SB_logoutMsg.content));
    }
    if (SB_webMenu && SB_webMenu.content) {
      dispatch(setWeb_Menu(SB_webMenu.content));
    }
    if (
      SB_languages &&
      SB_languages.content &&
      SB_languages.content.Language.length > 0
    ) {
      dispatch(setLanguages(SB_languages.content.Language));
    }
  }, [SB_logoutMsg, SB_webMenu, SB_languages, dispatch]);

  return <NavBar />;
};

export default Topbar;
