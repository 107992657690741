import React, { useEffect } from "react";
import { BrowserRouter, Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { useDispatch } from "react-redux";
import { Amplify, Auth, Hub } from "aws-amplify";

import { RootRoutes } from "./routes";
import theme from "./theme";
import { getLanguages } from "./store/app";
import { federatedLoginUser, getProfile } from "./store/session";
import { awsConfig } from "./config/aws-export";
import { history } from "./store/history";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-responsive-modal/styles.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./assets/css/custom.css";
import { ScrollToTop } from "./components/ScrollTop";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Amplify.configure(awsConfig);
const [localRedirectSignIn, productRedirectSignIn] =
  awsConfig.Auth.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productRedirectSignOut] =
  awsConfig.Auth.oauth.redirectSignOut.split(",");

const updateAwsConfig = {
  ...awsConfig,
  Auth: {
    ...awsConfig.Auth,
    oauth: {
      ...awsConfig.Auth.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productRedirectSignOut,
    },
  },
};
Amplify.configure(updateAwsConfig);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getLanguages());
  }, [dispatch]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          console.log("information =>", event, data);
          getUser().then((cognitoUser) => {
            dispatch(federatedLoginUser(cognitoUser));
          });
          break;
        case "signOut":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          console.log("Unknown auth event", event, data)
          break;
      }
    });
  }, [dispatch]);

  const getUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Router history={history}>
          <RootRoutes />
        </Router>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
