import React, {useEffect} from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "../components";
import { Main as MainLayout } from "../layouts";

import {
  LandingPage,
  Votes,
  HungryforInsights,
  HowItWorks,
  AboutUs,
  VegetarianFoods,
  VegetarianFoodDetails,
  PreferencesList,
  PreferencesEdit,
  PreferencesWizard,
  WeeklyPlans,
  Groceries,
  Definitions,
} from "../views/Main";
import ArticlePage from "../views/Main/HungryforInsights/ArticlePage";
import TermsAndConditions from "../views/Main/TermsAndConditions/TermsAndConditions";
import {useDispatch} from "react-redux";
import {checkAuthState} from "../store/session";


const MainRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={HowItWorks}
        exact
        layout={MainLayout}
        path="/how-it-works"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={HungryforInsights}
        exact
        layout={MainLayout}
        path="/hungry-for-insights"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={ArticlePage}
        exact
        layout={MainLayout}
        path="/hungry-for-insights/:slug"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={LandingPage}
        exact
        layout={MainLayout}
        path="/our-experts"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={AboutUs}
        exact
        layout={MainLayout}
        path="/about-us"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={TermsAndConditions}
        exact
        layout={MainLayout}
        path="/terms-and-conditions"
      />

      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={VegetarianFoods}
        exact
        layout={MainLayout}
        path="/vegetarian-foods"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={VegetarianFoodDetails}
        exact={true}
        layout={MainLayout}
        path="/vegetarian-food/detail/:id?"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={Votes}
        exact={false}
        layout={MainLayout}
        path="/vegetarian-food/detail/:id?/votes"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={PreferencesList}
        exact
        layout={MainLayout}
        path="/preferences/list"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={PreferencesEdit}
        exact
        layout={MainLayout}
        path="/preferences/edit"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={Definitions}
        exact
        layout={MainLayout}
        path="/definitions"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={PreferencesWizard}
        exact
        layout={MainLayout}
        path="/preferences/wizard"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={WeeklyPlans}
        exact
        layout={MainLayout}
        path="/weekly-plans"
      />
      <RouteWithLayout
        config={{
          hasHeader: true,
          hasFooter: true,
          hasSubscribeNews: false,
        }}
        component={Groceries}
        exact
        layout={MainLayout}
        path="/groceries"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default MainRoutes;
