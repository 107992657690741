import React from "react";
import { FiChevronLeft } from "react-icons/fi";

const ArrowLeft = (props) => {
  const { className, style, onClick, space } = props;
  return (
    <div
      className={className + " orangeBtn"}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        left: -space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronLeft style={{ fontSize: 20 }} />}
    </div>
  );
};

export default ArrowLeft;
