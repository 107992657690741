import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SignEdit, SocialSignButton, Button } from "../../../components";
import { loginUser } from "../../../store/session";
import { showToastr } from "../../../services/themeService";

import DotsImage from "../../../assets/image/background-dots3.png";
import { Auth } from "aws-amplify";
import Roma from "../../../assets/image/RomaFruta.png";
import { Topbar, Footer } from "./../../../layouts/Main/components";
import { useStoryblok } from "../../../services/storyblokService";
import Overlay from "components/Overlay";
import { validateEmail} from "../../../utils/emailValidation";

const Signin = () => {
  const storyPlan = useStoryblok("sign-in-or-sign-up");
  console.log(storyPlan);
  const dispatch = useDispatch();
  const isLoginLoading = useSelector((state) => state.session.loginLoading);
  const profileLoading = useSelector((state) => state.session.profileLoading);

  const [view, setView] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);


  const handleSignin = async (e) => {
    let hasError = false;

    e.preventDefault();

    if(!validateInputs()) return;

    dispatch(loginUser(email, password));
  };

  const handleGoogle = () => {
    Auth.federatedSignIn({ provider: "Google" });
  };

  const handleFacebook = () => {
    Auth.federatedSignIn({ provider: "Facebook" });
  };

  const handleApple = () => {
    Auth.federatedSignIn({ provider: "SignInWithApple" });
  };

  const validateInputs = () => {
    let isValid = true;

    if (!email || !validateEmail(email)) {
      showToastr("error", "Invalid Request", "Please input a valid email.");
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    if (!password) {
      showToastr("error", "Invalid Request", "Please input password.");
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }

    return isValid;
  };

  if (isLoginLoading || profileLoading) {
    return <Overlay></Overlay>;
  }

  return (
    <>
      <Topbar />
      <div className="row" style={{ height: "100%", width: "100%", margin: 0 }}>
        <div
          className="col-md-6 sign-img-container"
          style={{
            maxWidth: 960,
            padding: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Roma} style={{ width: "100%" }} alt={""}></img>
        </div>
        <div className="col-md-6 sign-auth-form">
          <div
            style={{
              backgroundImage: `url(${DotsImage})`,
              height: "100%",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div style={{ width: 450 }}>
              <p
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  color: "#07122E",
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                {storyPlan?.content?.Sign_in}
              </p>
              <form onSubmit={(e) => handleSignin(e)}>
                <span className="login-label">
                  {storyPlan?.content?.E_mail}
                </span>
                <SignEdit
                  user
                  type="text"
                  name="email"
                  placeholder={storyPlan?.content?.E_mail}
                  value={email}
                  onChange={(val) => setEmail(val)}
                  className={emailError ? "input-error" : ""}
                />
                <span className="login-label">
                  {storyPlan?.content?.Password}
                </span>
                <SignEdit
                  pass
                  eye
                  type="text"
                  name="pass"
                  view={view}
                  placeholder={storyPlan?.content?.Password}
                  onViewClick={() => setView(!view)}
                  value={password}
                  onChange={(val) => setPassword(val)}
                  className={passwordError ? "input-error" : ""}
                />
                <div style={{ margin: "20px 0 40px 0", textAlign: "center" }}>
                  <Link
                    to="/forgot-pass"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#6B6C72",
                      borderBottom: "1px solid gray",
                      textDecoration: "none",
                    }}
                  >
                    {storyPlan?.content?.Forgot_pwd}
                  </Link>
                </div>

                <input type="submit" hidden />

                <div
                  className="d-flex justify-content-start"
                  style={{ marginBottom: 50, marginLeft: "30%" }}
                >
                  <Button
                    variant="contained"
                    bgColor="primary"
                    fontColor="white"
                    width={190}
                    height={42}
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      borderRadius: "40px",
                    }}
                    onClick={handleSignin}
                  >
                    {storyPlan?.content?.Sign_in}
                  </Button>
                </div>

                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                >
                  <hr style={{ width: "20%" }}></hr>
                  <p className="or-login">
                    {storyPlan?.content?.SocialNetSign}
                  </p>
                  <hr style={{ width: "20%" }}></hr>
                </div>

                <div
                  className="d-flex"
                  style={{ marginBottom: 40, justifyContent: "center" }}
                >
                  <SocialSignButton
                    facebook
                    bgColor="#1673EA"
                    onClick={handleFacebook}
                  ></SocialSignButton>
                  <SocialSignButton
                    google
                    bgColor="#DC4234"
                    onClick={handleGoogle}
                  ></SocialSignButton>
                  <SocialSignButton
                    apple
                    bgColor="#9CA5A7"
                    onClick={handleApple}
                  ></SocialSignButton>
                </div>
              </form>
              <p className="create-account-paragraph">
                {storyPlan?.content?.No_account}{" "}
                <Link
                  to="/sign-up"
                  style={{
                    borderBottom: "1px solid gray",
                    color: "#07122E",
                    textDecoration: "none",
                    fontWeight: 600,
                  }}
                >
                  {storyPlan?.content?.Sign_up}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signin;
