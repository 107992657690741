import { useStoryblok } from "../../../services/storyblokService";
import { RichText } from "../../../utils/RichText";
import React from "react";
import styles from "../../../styles/TermsAndConditions.module.css";
import PageTemplate from "../../../components/PageTemplate";
import HeaderImg from "../../../assets/image/blog3.png";

const TermsAndConditions = () => {
  const SB_termsAndConditions = useStoryblok("terms-and-conditions");

  return (
    <>
      <PageTemplate
        backgroundImgSrc={HeaderImg}
        title={SB_termsAndConditions?.content?.Title}
      >
        <div className={styles.termsAndConditions_text}>
          {RichText(SB_termsAndConditions?.content?.Terms_and_conditions)}
        </div>
      </PageTemplate>
    </>
  );
};

export default TermsAndConditions;
