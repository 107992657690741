import React, { useEffect, useState } from "react";
import { useStoryblok } from "../../../services/storyblokService";
import Overlay from "../../../components/Overlay";
import styles from "../../../styles/HowItWorks.module.css";
import PageTemplate from "../../../components/PageTemplate";
import HeaderImg from "../../../assets/image/blog3.png";

const HowItWorks = (props) => {
  const [filteredSections, setFilteredSections] = useState([]);
  const [smallScreen, setSmallScreen] = useState(false);
  const SB_howItWorks = useStoryblok("how-it-works-section");

  const handleResize = () => {
    if (!smallScreen && window.innerWidth <= 900) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (SB_howItWorks?.content?.Hit_element?.length > 0) {
      let filteredSections = [];

      SB_howItWorks.content.Hit_element.forEach((element) => {
        if (element.Featured) {
          filteredSections.push({
            title: element.Title,
            description: element.Body,
            index: element.Order,
            iconSrc: element.Icon.filename,
            imgSrc: element.Image.filename,
          });
        }
      });
      filteredSections.sort((a, b) => a.index - b.index);
      setFilteredSections(filteredSections);
    }
  }, [SB_howItWorks?.content?.Hit_element]);

  if (!SB_howItWorks) {
    return <Overlay></Overlay>;
  }

  return (
    <>
      <PageTemplate
        backgroundImgSrc={HeaderImg}
        title={SB_howItWorks?.content?.Title}
      >
        {filteredSections.map((element, index) => {
          const isWithImageOnRight = index % 2 === 0;
          const iconBackgroundColor = () => {
            switch (index) {
              case 0:
                return "rgb(116, 211, 155)";
              case 1:
                return "rgb(245, 220, 96)";
              case 2:
                return "rgb(255, 124, 124)";
              case 3:
                return "rgb(197, 170, 255)";
              case 4:
                return "rgb(91, 186, 255)";
            }
          };

          return (
            <div className={styles.howItWorks_item}>
              {(!isWithImageOnRight || smallScreen) && (
                <img src={element.imgSrc}></img>
              )}
              <div
                className={styles.howItWorks_text}
                style={{
                  marginRight: smallScreen
                    ? "unset"
                    : isWithImageOnRight
                    ? "10%"
                    : "unset",
                  marginLeft: smallScreen
                    ? "unset"
                    : !isWithImageOnRight
                    ? "10%"
                    : "unset",
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={element.iconSrc}
                    style={{
                      backgroundColor: `${iconBackgroundColor()}`,
                      padding: 10,
                      borderRadius: "50%",
                      marginRight: 20,
                    }}
                  ></img>
                  <span
                    style={{
                      maxWidth: 445,
                      fontSize: 30,
                      fontWeight: 700,
                      color: "#07122E",
                    }}
                  >
                    {element.title}
                  </span>
                </div>
                <p className={styles.howItWorks_text_p}>
                  {element.description}
                </p>
              </div>
              {!smallScreen && isWithImageOnRight && (
                <img src={element.imgSrc}></img>
              )}
            </div>
          );
        })}
      </PageTemplate>
    </>
  );
};

export default HowItWorks;
