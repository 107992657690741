import React from "react";
import { Pagination as MuiPagination } from "@mui/material";

const Pagination = ({ numPages, page, onChangePage }) => {
  return (
    <MuiPagination
      count={numPages}
      page={page}
      onChange={(e, page) => onChangePage(e, page)}
      shape="rounded"
      sx={{
        "& .MuiButtonBase-root": {
          border: "1px solid #FAA72D",
          color: "#FAA72D",
        },
        "& .MuiButtonBase-root.Mui-selected": {
          backgroundColor: "#FAA72D",
          color: "white",
        },
      }}
    />
  );
};

export default Pagination;
