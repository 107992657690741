import React from "react";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { FiStar } from "react-icons/fi";

const CarouselItem4 = (props) => {
  const { avatar, name, job, description, rating } = props;

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{
        padding: "20px",
        margin: "10px",
        boxShadow: "0px 0px 15px 0px #3535351F",
        borderRadius: "6px"
      }}
    >
      <span
        className="line-clamp-3"
        style={{
          maxWidth: 300,
          fontSize: 18,
          fontWeight: 300,
          fontStyle: "italic",
          fontFamily: "Merriweather",
          color: "#07122E",
          marginBottom: 30,
          textAlign: "center",
          height: 81,
        }}
      >
        {description}
      </span>
      <img
        src={avatar}
        style={{
          borderRadius: "50%",
          width: 80,
          height: 80,
          marginBottom: 20,
          objectFit: "cover",
        }}
        alt={""}
        loading={"lazy"}
      ></img>
      <Rating
        emptySymbol={<FiStar style={{ color: "#FC9C52", width: 22 }} />}
        fullSymbol={<FaStar style={{ color: "#FC9C52", width: 22 }} />}
        initialRating={rating}
      />
      <span
        style={{
          fontSize: 18,
          fontWeight: 700,
          color: "#07122E",
          marginBottom: 3,
          marginTop: 20,
        }}
      >
        {name}
      </span>
      <span style={{ fontSize: 16, fontWeight: 600, color: "#9EB152" }}>
        {job}
      </span>
    </div>
  );
};

export default CarouselItem4;
