import React from "react";
import { Link } from "react-router-dom";
import {
  FaPinterestP,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import styles from "../../styles/CarouselItem2.module.css";

const CarouselItem2 = (props) => {
  const { avatar, name, job } = props;

  return (
    <div className="d-flex flex-column align-items-center">
      <div>
        <img src={avatar} className={styles.carouselItem2_img} alt={""} loading={"lazy"} />
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          borderRadius: 6,
          backgroundColor: "white",
          width: 150,
          position: "absolute",
          bottom: 90,
          height: 30,
          padding: "0 10px",
        }}
      >
        <Link to="">
          <FaFacebookF color="gray" />
        </Link>
        <Link to="">
          <FaTwitter color="gray" />
        </Link>
        <Link to="">
          <FaLinkedinIn color="gray" />
        </Link>
        <Link to="">
          <FaPinterestP color="gray" />
        </Link>
      </div>
      <span
        style={{
          fontSize: 18,
          fontWeight: 700,
          color: "#07122E",
          marginBottom: 3,
        }}
      >
        {name}
      </span>
      <span style={{ fontSize: 16, fontWeight: 600, color: "#9EB152" }}>
        {job}
      </span>
    </div>
  );
};

export default CarouselItem2;
