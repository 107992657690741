export const WEB_MENU = "WEB_MENU";
export const LANGUAGES = "LANGUAGES";
export const BANNERS = "BANNERS";
export const LOGO = "LOGO";
export const CLEAN = "CLEAN";
export const ABOUTUS = "ABOUTUS";
export const LOGOUTMSG = "LOGOUTMSG";
export const ARTICLESSECTION = "ARTICLESSECTION";
export const ARTICLES = "ARTICLES";
export const HOWITWORKS = "HOWITWORKS";
