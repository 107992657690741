import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader, ClipLoader } from "react-spinners";
import {
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { FuncService } from "../../../services/funcService";
import { showToastr } from "../../../services/themeService";
import {checkAuthState, updateProfile} from "../../../store/session";
import {
  getCountries,
  getCuisineList,
  getIngredientList,
  getVegetarianTypeList,
  getRDDEvaluations,
  selectLanguage,
} from "../../../store/app";
import {
  LineEdit,
  CheckBox,
  Button,
  Dialog,
  Pagination,
} from "../../../components";
import BackgroundImage from "../../../assets/image/vegetarian-foods.png";
import { useStoryblok } from "../../../services/storyblokService";
import styles from "./EditPreferences.module.css";
import PageTemplate from "../../../components/PageTemplate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Search from "../../../components/Search";
import {Redirect} from "react-router-dom";


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 190,
    borderRadius: 25,
  }),
  option: (provided) => ({
    ...provided,
  }),
};

const genderOptions = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
];

const Edit = () => {
  const dispatch = useDispatch();


  const SB_preferences = useStoryblok("preferences");
  const SB_userInformation = useStoryblok("user-information");
  const SB_languages = useStoryblok("languages");

  const {
    countries,
    cuisines,
    language,
    languages,
    vegetarianTypes,
    ingredientInfo,
  } = useSelector((state) => state.app);
  const loading = useSelector((state) => state.session.profileLoading);
  const { isAuthenticated, user, profile, loginLoading, profileLoading } = useSelector(state => state.session);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(null);
  const [birthDay, setBirthDay] = useState();
  const [gender, setGender] = useState(0);
  const [userLanguage, setUserLanguage] = useState(0);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedVegetarianTypes, setSelectedVegetarianTypes] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [keywords, setKeywords] = useState({
    cuisine: "",
    vegetarianType: "",
    ingredient: "",
  });
  const [search, setSearch] = useState({
    cuisine: "",
    vegetarianType: "",
    ingredient: "",
  });

  const [cuisineOpen, setCuisineOpen] = useState(false);
  const [vegetarianOpen, setVegetarianOpen] = useState(false);
  const [ingredientOpen, setIngredientOpen] = useState(false);
  const [group, setGroup] = useState(1);
  const [availableLangs, setAvailableLangs] = useState([]);
  const [selectedLang, setSelectedLang] = useState();

  const count = 10;
  const defaultLanguage = profile?.langId ?? 1;

  useEffect(() => {
    if (SB_languages?.content?.Language.length > 0) {
      let aux = [];
      SB_languages?.content?.Language.forEach((element) => {
        if (element.Enabled)
          aux.push({ ...element, value: element.Id, label: element.Name });
      });
      setAvailableLangs(aux);
      setSelectedLang(aux.find((l) => l.Id === language));
    }
  }, [SB_languages, language]);

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(checkAuthState());
      setIsCheckingAuth(false);
    };
    checkAuth();
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getRDDEvaluations());
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handlePageChange = (val) => {
    setPage(val);

    dispatch(getIngredientList(val, count, "code", "asc", keywords.ingredient));
  };

  const ClearAllByType = (type) => {
    switch (type) {
      case "cuisines":
        setSelectedCuisines([]);
        break;
      case "vegTypes":
        setSelectedVegetarianTypes([]);
        break;
      case "ingredients":
        setSelectedIngredients([]);
        break;
    }
  };

  const handleCuisineCheck = (id) => {
    let tmpCuisines = selectedCuisines.slice();
    if (!tmpCuisines.includes(id)) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setSelectedCuisines(tmpCuisines);
  };

  const handleVegetarianCheck = (id) => {
    let tmpVegetarianTypes = selectedVegetarianTypes.slice();
    if (!tmpVegetarianTypes.includes(id)) {
      tmpVegetarianTypes = [id];
    } else {
      const index = tmpVegetarianTypes.indexOf(id);
      tmpVegetarianTypes.splice(index, 1);
    }

    setSelectedVegetarianTypes(tmpVegetarianTypes);
  };

  const handleIngredientCheck = (e, code) => {
    let tmpIngredients = [...selectedIngredients];
    if (!tmpIngredients.includes(code)) {
      // if (tmpIngredients.length > 4) {
      //   e.preventDefault();

      //   return;
      // }

      tmpIngredients.push(code);
    } else {
      const index = tmpIngredients.indexOf(code);
      tmpIngredients.splice(index, 1);
    }

    setSelectedIngredients(tmpIngredients);
  };

  const handleEdit = () => {
    setName(profile.name);
    setEmail(profile.email);
    setCountry(
      profile.countryId
        ? countryOptions.find((country) => country.value === profile.countryId)
        : countryOptions[0]
    );
    setGender(
      genderOptions.find((gender) => gender.label === profile.gender) ??
        genderOptions[0]
    );
    setBirthDay(new Date(profile.birthday));
    setUserLanguage(
      languageOptions.find((language) => language.value === defaultLanguage) ??
        languageOptions[0]
    );

    setEditMode(!editMode);
  };

  const handleOnChangePhoto = (image) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const imageData = e.target.result;

      dispatch(
        updateProfile({ ...profile, imageName: image.name, image: imageData })
      );
    };

    reader.readAsDataURL(image);
  };

  const handleSave = () => {
    console.log("name", name);
    console.log("email", email);
    console.log("country", country);
    console.log("gender", gender);
    console.log("birthDay", birthDay);
    console.log("language", userLanguage);

    if (!name || !email || !country || !gender || !birthDay || !userLanguage) {
      showToastr("error", "Input request", "Please complete form.");

      return;
    }

    let updatedProfile = {};

    updatedProfile.name = name;
    updatedProfile.email = email;
    updatedProfile.country = country.value;
    updatedProfile.birthday = birthDay;
    updatedProfile.gender = gender.label;
    updatedProfile.langId = userLanguage.value;
    updatedProfile.cuisineList = profile.cuisines;
    updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
    updatedProfile.unlikeList = profile.UnlikeIngredients;
    if (profile.userGroupId) updatedProfile.userGroupId = profile.userGroupId;

    dispatch(updateProfile(updatedProfile));

    setEditMode(false);
  };



  const handleEdits = (type) => {
    if (type === 0) {
      setSelectedCuisines(profile.cuisines);
      setCuisineOpen(true);
    } else if (type === 1) {
      setSelectedVegetarianTypes(profile.vegetarianTypes);
      setVegetarianOpen(true);
    } else if (type === 2) {
      setSelectedIngredients(
        profile.unlikeIngredients.map((ingredient) => ingredient.id)
      );
      setIngredientOpen(true);
    }
  };

  const handleApply = (type) => {
    let updatedProfile = {};
    updatedProfile.name = profile.name;
    updatedProfile.email = profile.email;
    updatedProfile.city = profile.city;
    updatedProfile.country = profile.countryId;
    updatedProfile.birthday = profile.birthday;
    updatedProfile.gender = profile.gender;

    if (profile.userGroupId) updatedProfile.userGroupId = profile.userGroupId;
    if (type === 0) {
      updatedProfile.cuisineList = selectedCuisines;
      updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
      updatedProfile.unlikeList = profile.unlikeIngredients;
    }
    if (type === 1) {
      updatedProfile.cuisineList = profile.cuisines;
      updatedProfile.vegetarianTypeList = selectedVegetarianTypes;
      updatedProfile.unlikeList = profile.likeIngredients;
    }
    if (type === 2) {
      updatedProfile.cuisineList = profile.cuisines;
      updatedProfile.vegetarianTypeList = profile.vegetarianTypes;
      updatedProfile.unlikeList = selectedIngredients;
    }

    dispatch(updateProfile(updatedProfile));

    if (type === 0) setCuisineOpen(false);
    else if (type === 1) setVegetarianOpen(false);
    else if (type === 2) setIngredientOpen(false);

    setSearch({
      cuisine: "",
      vegetarianType: "",
      ingredient: "",
    });
  };

  const handleGroup = (group) => {
    setEditMode(false);
    setGroup(group);
  };

  const handleLanguageChange = (val) => {
    dispatch(selectLanguage(val?.Id, true));
    if (profile) {
      for (const element in languages) {
        if (languages[element].code.toLowerCase() === val?.Id) {
          profile.langId = parseInt(element);
          //have to delete image because off error on backend
          delete profile.image;
          dispatch(updateProfile(profile));
        }
      }
    }
  };
  if (isCheckingAuth || loginLoading || profileLoading) {
    return (<div className="HV-center h-100">
      <BeatLoader color="#FC9C52" size={20} margin={5}/>
    </div>);
  }

  if (!isAuthenticated || !user) {
    console.log(`User is not authenticated: \nisAuthenticated: ${isAuthenticated}, \nuser: ${user}, \nprofile: ${profile}, \nloginLoading${loginLoading}, \nprofileLoading: ${profileLoading}`);
    return <Redirect to="/sign-in"/>;
  }

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  const loadingStatus = {
    profile: !!profile,
    cuisines: !!cuisines,
    vegetarianTypes: !!vegetarianTypes,
    languages: !!languages
  };

  const allLoaded = Object.values(loadingStatus).every(Boolean);

  if (!allLoaded) {
    console.log('Loading status:', loadingStatus);
    const notLoaded = Object.entries(loadingStatus)
      .filter(([, loaded]) => !loaded)
      .map(([item]) => item)
      .join(', ');
    console.log(`Not loaded: ${notLoaded}`);

    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="HV-center h-100">
        <ClipLoader color="#FC9C52" size={35} />
      </div>
    );
  }

  let cuisineArray = [];
  profile.cuisines.forEach((cuisine) => {
    if(cuisines[cuisine]?.name){
      cuisineArray.push(cuisines[cuisine].name);
    }
  });

  let vegetarianArray = [];
  profile.vegetarianTypes.forEach((vegetarian) => {
    vegetarianArray.push(vegetarianTypes[vegetarian].name);
  });

  let ingredientArray = [];
  profile.unlikeIngredients.forEach((ingredient) => {
    ingredientArray.push(ingredient.description[defaultLanguage]);
  });

  let countryOptions = [];
  if (countries) {
    for (const id in countries) {
      countryOptions.push({
        value: parseInt(id),
        label: countries[id].name,
      });
    }
  }

  let languageOptions = [];
  if (languages) {
    for (const id in languages) {
      languageOptions.push({
        value: parseInt(id),
        label: languages[id].name,
      });
    }
  }

  const renderNavItem = (element, group, text) => {
    return (
      <ListItem
        onClick={() => handleGroup(element)}
        sx={{
          textAlign: "center",
          borderRadius: 28,
          background: `${
            group === element
              ? "linear-gradient(180deg, #faa72d 0%, #f28231 100%)"
              : "white"
          }`,
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px",
          width: "20%",
          minWidth: "min-content",
          padding: "12px 40px",

          "&:hover": {
            background: "linear-gradient(180deg, #faa72d 0%, #f28231 100%)",

            "& .MuiTypography-root": {
              color: "white",
            },
          },

          "& .MuiTypography-root": {
            color: `${group === element ? "white" : "#6B6C72"}`,
            fontSize: 18,
            fontWeight: 500,
          },
        }}
      >
        <ListItemText
          primary={text}
          className={styles.editPreferences_text}
          style={{ textAlign: "center", borderRadius: 28 }}
        />
      </ListItem>
    );
  };

  return (
    <>
      <PageTemplate
        backgroundImgSrc={BackgroundImage}
        title={SB_userInformation?.content?.Profile_title}
      >
        <div style={{ backgroundColor: "white", padding: "30px 50px" }}>
          <div className={styles.editPreferences_groups}>
            <List
              component="div"
              aria-label="secondary mailbox folders"
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: 40,
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {renderNavItem(1, group, SB_userInformation?.content?.About_you)}
              {renderNavItem(2, group, SB_preferences?.content?.Cuisine)}
              {renderNavItem(
                3,
                group,
                SB_preferences?.content?.Vegetarian_types
              )}
              {renderNavItem(4, group, SB_preferences?.content?.Ingredients)}
            </List>
          </div>
          <div className={styles.editPreferences_group}>
            {group === 1 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <p className="edit-preferences">Edit</p>
                  <Button
                    variant=""
                    width={40}
                    height={40}
                    style={{ padding: 0 }}
                    onClick={handleEdit}
                  >
                    <BsPencil style={{ color: "#B8BABD", fontSize: 18 }} />
                  </Button>
                </div>
                <div
                  className={styles.editPreferences_data}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      height: "100%",
                      gap: 40,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Name}
                        </p>
                        {editMode ? (
                          <LineEdit
                            placeholder={SB_userInformation?.content?.Name}
                            style={{
                              height: 45,
                              margin: 0,
                              padding: 10,
                            }}
                            value={name}
                            onChange={(val) => setName(val)}
                          />
                        ) : (
                          <span className="profile-value">{profile.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Email}
                        </p>
                        {editMode ? (
                          <LineEdit
                            placeholder={SB_userInformation?.content?.Email}
                            style={{
                              height: 45,
                              margin: 0,
                              padding: 10,
                            }}
                            readOnly
                            value={email}
                            onChange={(val) => setEmail(val)}
                          />
                        ) : (
                          <span className="profile-value">{profile.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Country}
                        </p>
                        {editMode ? (
                          <Select
                            options={countryOptions}
                            isSearchable={true}
                            styles={customStyles}
                            value={country}
                            defaultValue={
                              countryOptions.find(
                                (countryOption) =>
                                  countryOption.value === profile.countryId
                              ) ?? countryOptions[0]
                            }
                            onChange={(val) => setCountry(val)}
                          />
                        ) : (
                          <span className="profile-value">
                            {countries && profile && profile.countryId
                              ? countries[profile.countryId]?.name
                              : ""}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "100%",
                      gap: 40,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Gender}
                        </p>
                        {editMode ? (
                          <Select
                            options={genderOptions}
                            isSearchable={false}
                            styles={customStyles}
                            defaultValue={
                              genderOptions.find(
                                (genderOption) =>
                                  genderOption.label === profile.gender
                              ) ?? genderOptions[0]
                            }
                            onChange={(val) => setGender(val)}
                          />
                        ) : (
                          <span className="profile-value">
                            {profile.gender}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Language}
                        </p>
                        {editMode ? (
                          <Select
                            placeholder={SB_userInformation?.content?.Language}
                            options={availableLangs}
                            isSearchable={true}
                            styles={customStyles}
                            value={selectedLang}
                            onChange={(val) => handleLanguageChange(val)}
                          />
                        ) : (
                          <span className="profile-value">
                            {selectedLang?.Name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="profile-subject">
                          *{SB_userInformation?.content?.Birthday}
                        </p>
                        {editMode ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD-MM-YYYY"
                              disableFuture
                              defaultValue={
                                birthDay
                                  ? dayjs(birthDay)
                                  : dayjs(profile.birthday)
                              }
                              maxDate={dayjs().subtract(14, "year")}
                              onChange={(date, context) => {
                                if (!context.validationError) setBirthDay(date);
                                else setBirthDay(undefined);
                              }}
                              sx={{ width: "95%" }}
                            />
                          </LocalizationProvider>
                        ) : (
                          <span className="profile-value">
                            {FuncService.formatDate(profile.birthday)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="image-profile">
                    <img
                      src={profile.image}
                      style={{
                        borderRadius: "50%",
                        width: 147,
                        height: 147,
                        objectFit: "cover",
                      }}
                      alt={"profile image"}
                    ></img>
                    <label
                      style={{
                        display: "block",
                        fontFamily: "Roboto",
                        fontSize: 17,
                        color: "#F99345",
                        marginTop: 18,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        document.getElementById("inputImage").click();
                      }}
                      id="profileImage"
                      htmlFor="profileImage"
                    >
                      {SB_userInformation?.content?.Change_photo}
                    </label>
                    <input
                      id="inputImage"
                      className={styles.editPreferences_InputImage}
                      type="file"
                      name="profileImage"
                      accept="image/*"
                      onChange={(event) => {
                        console.log(event.target.files[0]);
                        handleOnChangePhoto(event.target.files[0]);
                        // setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                {editMode ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: 64 }}
                  >
                    <Button
                      variant="contained"
                      bgColor="primary"
                      fontColor="white"
                      width="40%"
                      style={{
                        background:
                          "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
                        boxShadow: "1px 9px 31px rgba(0, 0, 0, 0.25)",
                        borderRadius: "100px",
                      }}
                      onClick={handleSave}
                    >
                      <span style={{ fontSize: 18, fontWeight: 600 }}>
                        {SB_userInformation?.content?.Confirm}
                      </span>
                    </Button>
                  </div>
                ) : null}
              </>
            ) : group === 2 ? (
              <div
                className="d-flex align-items-center"
                style={{ gap: "30px" }}
              >
                <div style={{ width: "100%" }}>
                  <p className="profile-subject">
                    {SB_preferences?.content?.Cuisine_help}
                  </p>
                  <span className="profile-value">
                    {cuisineArray.join(", ")}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="edit-preferences" style={{ margin: 0 }}>
                    Edit
                  </p>
                  <Button
                    variant=""
                    width={40}
                    height={40}
                    style={{ padding: 0 }}
                    onClick={() => handleEdits(0)}
                  >
                    <BsPencil style={{ color: "#B8BABD", fontSize: 18 }} />
                  </Button>
                </div>
              </div>
            ) : group === 3 ? (
              <div
                className="d-flex align-items-center"
                style={{ gap: "30px" }}
              >
                <div style={{ width: "100%" }}>
                  <p className="profile-subject">
                    {SB_preferences?.content?.Vegetarian_types_help}
                  </p>
                  <span className="profile-value">
                    {vegetarianArray.join(", ")}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="edit-preferences" style={{ margin: 0 }}>
                    Edit
                  </p>
                  <Button
                    variant=""
                    width={40}
                    height={40}
                    style={{ padding: 0 }}
                    onClick={() => handleEdits(1)}
                  >
                    <BsPencil style={{ color: "#B8BABD", fontSize: 18 }} />
                  </Button>{" "}
                </div>
              </div>
            ) : (
              <div
                className="d-flex align-items-center"
                style={{ gap: "30px" }}
              >
                <div style={{ width: "100%" }}>
                  <p className="profile-subject">
                    {SB_preferences?.content?.Ingredients_help}
                  </p>
                  <span className="profile-value">
                    {ingredientArray.join(", ")}
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="edit-preferences" style={{ margin: 0 }}>
                    Edit
                  </p>
                  <Button
                    variant=""
                    width={40}
                    height={40}
                    style={{ padding: 0 }}
                    onClick={() => handleEdits(2)}
                  >
                    <BsPencil style={{ color: "#B8BABD", fontSize: 18 }} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageTemplate>

      {/* cuisine */}
      <Dialog
        fullWidth={true}
        open={cuisineOpen}
        onClose={() => setCuisineOpen(false)}
        maxWidth={"md"}
      >
        <p className="dialogTitle">{SB_preferences?.content.Cuisine}</p>
        <p className="dialogSubtitle">
          {" "}
          {SB_preferences?.content.Cuisine_help}
        </p>
        <div className={styles.editPreferences_dialog_content}>
          <div className={styles.editPreferences_dialog_filter}>
            {/* <div className="d-flex justify-content-end mt-2">
              <TextField
                onChange={(e) => {
                  setKeywords({ ...keywords, cuisine: e.target.value });
                  handleSearch("cuisine", e);
                }}
                sx={{
                  m: 0,
                  width: "25ch",
                  "& .MuiInputBase-root": {
                    borderRadius: "20px",
                    boxShadow: "0px 0px 15px 0px #00000036",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#E0E3E7",
                    },
                    "&:hover fieldset": {
                      borderColor: "#faa72d",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#faa72d",
                    },
                  },
                }}
                placeholder={SB_preferences?.content.Example_Ingredient}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchImage}></img>
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
            <div className="mt-2 clearAllContainer">
              <span onClick={() => ClearAllByType("cuisines")}>
                {SB_preferences?.content.ClearAll}
              </span>
              <RiDeleteBin6Line
                onClick={() => ClearAllByType("cuisines")}
                style={{
                  fontSize: 18,
                  verticalAlign: "text-bottom",
                  marginLeft: 2,
                }}
              />
            </div>
          </div>
          <div
            style={{
              color: "#6B6C72",
              fontSize: 16,
              fontWeight: 400,
              height: 200,
              width: "100%",
              overflowY: "scroll",
              padding: "0 5%",
              marginTop: 10,
            }}
          >
            {Object.entries(cuisines).map((cuisine, index) => {
              const cuisineLowerCase = cuisine[1].name.toLowerCase();
              return cuisineLowerCase.includes(search.cuisine) ? (
                <CheckBox
                  style={{ padding: "5px 0" }}
                  id={`cuisine_${parseInt(cuisine[0])}`}
                  key={`cuisine_${index}`}
                  checked={selectedCuisines.includes(parseInt(cuisine[0]))}
                  onClick={() =>
                    handleCuisineCheck(parseInt(parseInt(cuisine[0])))
                  }
                >
                  {cuisine[1].name}
                </CheckBox>
              ) : null;
            })}
          </div>
        </div>
        <div className="d-flex p-5">
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            height={42}
            style={{
              marginTop: 30,
              margin: "auto",
              background: "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
              boxShadow: "1px 9px 25px 0px #00000026",
              borderRadius: "25.5px",
              padding: "0px 30px",
            }}
            onClick={() => handleApply(0)}
          >
            {SB_preferences?.content.Save}
          </Button>
        </div>
      </Dialog>

      {/* vegetarian types */}
      <Dialog
        fullWidth={false}
        open={vegetarianOpen}
        onClose={() => setVegetarianOpen(false)}
      >
        <p className="dialogTitle">
          {SB_preferences?.content.Vegetarian_types}
        </p>
        <p className="dialogSubtitle">
          {SB_preferences?.content.Vegetarian_types_help}
        </p>
        <div className="mt-2 clearAllContainer">
          <span onClick={() => ClearAllByType("vegTypes")}>
            {SB_preferences?.content.ClearAll}
          </span>
          <RiDeleteBin6Line
            onClick={() => ClearAllByType("vegTypes")}
            style={{
              fontSize: 18,
              verticalAlign: "text-bottom",
              marginLeft: 2,
            }}
          />
        </div>
        <div
          style={{
            color: "#6B6C72",
            fontSize: 16,
            fontWeight: 400,
            height: 200,
            width: "100%",
            overflowY: "auto",
            padding: "0 5%",
            marginTop: 10,
          }}
        >
          {Object.entries(vegetarianTypes).map((vegetarian, index) =>
            vegetarian[1].name.includes(search.vegetarianType) ? (
              <CheckBox
                style={{ padding: "5px 0" }}
                id={`vegetarian_${parseInt(vegetarian[0])}`}
                key={`vegetarian_${index}`}
                checked={
                  selectedVegetarianTypes.includes(parseInt(vegetarian[0]))
                }
                onClick={() =>
                  handleVegetarianCheck(parseInt(parseInt(vegetarian[0])))
                }
              >
                {vegetarian[1].name}
              </CheckBox>
            ) : null
          )}
        </div>
        <div className="d-flex p-5">
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            height={42}
            style={{
              marginTop: 30,
              margin: "auto",
              background: "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
              boxShadow: "1px 9px 25px 0px #00000026",
              borderRadius: "25.5px",
              padding: "0px 30px",
            }}
            onClick={() => handleApply(1)}
          >
            {SB_preferences?.content.Save}
          </Button>
        </div>
      </Dialog>

      {/*indredients*/}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={ingredientOpen}
        onClose={() => setIngredientOpen(false)}
      >
        <p className="dialogTitle">{SB_preferences?.content.Ingredients}</p>
        <p className="dialogSubtitle">
          {" "}
          {SB_preferences?.content.Ingredients_help}
        </p>
        <div className={styles.editPreferences_dialog_content}>
          <div className={styles.editPreferences_dialog_filter}>
            <div className="d-flex justify-content-end mt-2">
              <Search
                value={keywords.ingredient}
                placeholder={SB_preferences?.content.Example_Ingredient}
                onChange={(keyword) => {
                  setPage(1);
                  dispatch(getIngredientList(1, count, "code", "asc", keyword));
                  setKeywords({ ...keywords, ingredient: keyword });
                }}
              ></Search>
            </div>
            <div className="mt-2 clearAllContainer">
              <span onClick={() => ClearAllByType("cuisines")}>
                {SB_preferences?.content.ClearAll}
              </span>
              <RiDeleteBin6Line
                onClick={() => ClearAllByType("ingredients")}
                style={{
                  fontSize: 18,
                  verticalAlign: "text-bottom",
                  marginLeft: 2,
                }}
              />
            </div>
          </div>
          <div
            style={{
              color: "#6B6C72",
              fontSize: 16,
              fontWeight: 400,
              height: 200,
              width: "100%",
              overflowY: "scroll",
              padding: "0 5%",
              marginTop: 10,
            }}
          >
            {ingredientInfo ? (
              ingredientInfo.ingredients.map((ingredient) => (
                <CheckBox
                  style={{ padding: "5px 0" }}
                  id={`ingredient_${ingredient.id}`}
                  key={`ingredient_${ingredient.id}`}
                  checked={
                    selectedIngredients.includes(ingredient.id)
                  }
                  onClick={(e) => handleIngredientCheck(e, ingredient.id)}
                >
                  {ingredient.friendlyDescription[defaultLanguage]}
                </CheckBox>
              ))
            ) : (
              <div className="HV-center h-100">
                <BeatLoader color="#FC9C52" size={20} margin={5} />
              </div>
            )}
          </div>
        </div>
        <Pagination
          numPages={ingredientInfo?.pages}
          page={page}
          onChangePage={(e, page) => handlePageChange(page)}
        />
        <div className="d-flex p-5">
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            height={42}
            style={{
              marginTop: 30,
              margin: "auto",
              background: "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
              boxShadow: "1px 9px 25px 0px #00000026",
              borderRadius: "25.5px",
              padding: "0px 30px",
            }}
            onClick={() => handleApply(2)}
          >
            {SB_preferences?.content.Save}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default Edit;
