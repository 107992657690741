import { debounce } from 'lodash';

export const createDebouncedAction = (actionCreator, wait = 300) => {
  const debouncedDispatch = debounce((dispatch, ...args) => {
    dispatch(actionCreator(...args));
  }, wait);

  return (...args) => (dispatch) => {
    debouncedDispatch(dispatch, ...args);
  };
};