import React from "react";
import PropTypes from "prop-types";
import { RiSendPlaneFill } from "react-icons/ri";

import {
  SubscribeBar,

} from "../../../../components";

import FooterImage from "../../../../assets/image/footer.png";

import { useStoryblok } from "../../../../services/storyblokService";
import Logo from "../../../../components/Logo.js";

const Footer = ({ hasSubscribeNews }) => {
  const SB_footer = useStoryblok("footer");
  const storyPlanNewsletter = useStoryblok("subscribe-newsletter");

  return (
    <>
      <div style={{ width: "100%" }}>
        {hasSubscribeNews && (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{
              height: 530,
              width: "100%",
              backgroundImage: `url(${FooterImage})`,
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                width: 60,
                paddingBottom: 15,
                borderBottom: "2px solid #FC9C52",
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: 38,
                  fontWeight: "bold",
                  color: "#07122E",
                  textAlign: "center",
                }}
              >
                {storyPlanNewsletter?.content?.Title}
              </span>
            </div>
            <span
              className="text-center"
              style={{
                width: 540,
                marginTop: 30,
                fontSize: 18,
                fontWeight: 400,
                color: "#6B6C72",
              }}
            >
              {storyPlanNewsletter?.content?.Body}
            </span>

            <SubscribeBar />
          </div>
        )}
        <div
          style={{
            backgroundColor: "#D8E2C0",
            paddingTop: hasSubscribeNews ? "20px" : "0px",
            marginTop: hasSubscribeNews ? "0px" : "20px",
          }}
        >
          <div style={{ maxWidth: "85%", margin: "auto", padding: "0 5%" }}>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                borderBottom: "1px solid #202a43",
              }}
            >
              <div className="mb-3">
                <Logo width={"60px"}></Logo>
              </div>
            </div>
            <div
              style={{
                padding: "30px 10%",
                color: "black",
                fontSize: "80%",
                fontFamily: "Roboto",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                gap: 10,
              }}
            >
              <span>{SB_footer?.content?.My_Perfect_Meal_Year}</span>
              <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                <span>{SB_footer?.content?.Get_support}</span>
                <span>{SB_footer?.content?.Contact_us}</span>
                <span>{SB_footer?.content?.Advertise}</span>
              </div>
              <div className="d-flex align-items-center">
                <RiSendPlaneFill
                  style={{ fontSize: 15, color: "#3490DD", marginRight: 5 }}
                />
                <span style={{ fontSize: 15 }}>
                  {SB_footer?.content?.ContactMail}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
