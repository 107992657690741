import React from "react";
import Slider from "react-slick";

import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick, space } = props;
  return (
    <div
      className={className + " orangeBtn"}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        right: -space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronRight style={{ fontSize: 20 }} />}
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, space } = props;
  return (
    <div
      className={className + " orangeBtn"}
      style={{
        ...style,
        display: "block",
        width: 40,
        height: 40,
        left: -space,
        zIndex: 1,
      }}
      onClick={onClick}
    >
      {<FiChevronLeft style={{ fontSize: 20 }} />}
    </div>
  );
}

const SliderTest = (props) => {
  const {
    children,
    dots,
    items,
    space,
    center,
    style,
    fade,
    slidesToScroll,
    infinite,
    responsive,
  } = props;
  
  const settings = {
    className: "center",
    dots: dots,
    infinite: infinite,
    speed: 2000,
    slidesToShow: items,
    slidesToScroll: slidesToScroll,
    centerMode: center,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 5000,
    initialSlide: 0,
    fade: fade,
    nextArrow: <SampleNextArrow space={space} />,
    prevArrow: <SamplePrevArrow space={space} />,
    responsive: responsive,
  };

  return (
    <Slider {...settings} style={style}>
      {children}
    </Slider>
  );
};

export default SliderTest;
