import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Breadcrums = ({ data }) => {
  const renderLinks = data.map((element) => {
    if (element.link) {
      return (
        <Link underline="hover" color="inherit" to={element.href} key={element.href}>
          {element.name}
        </Link>
      );
    } else if (element.text) {
      return <Typography color="text.primary">{element.name}</Typography>;
    }

    return null;
  });

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {renderLinks}
      {/* <Link underline="hover" color="inherit" href="/">
        MUI
      </Link>
      <Link
        underline="hover"
        color="inherit"
        href="/material-ui/getting-started/installation/"
      >
        Core
      </Link>
      <Typography color="text.primary">Breadcrumbs</Typography> */}
    </Breadcrumbs>
  );
};

export default Breadcrums;
