import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "../components";
import { Main as MainLayout } from "../layouts";

import { NotFound as NotFoundView } from "../views";

import {
  SignIn as SignInView,
  SignUp as SignUpView,
  ForgotPass as ForgotPassView,
  ResetPass as ResetPassView,
  ConfirmUser as ConfirmUserView,
  TermsService as TermsServiceView,
} from "../views/Auth";

import MainRoutes from "./MainRoutes";
import { shallowEqual, useSelector } from "react-redux";
import LogOutSocial from "../views/LogOutSocial";

const RootRoutes = () => {
  const { isAuthorized } = useSelector(
    ({ session }) => ({
      isAuthorized:
        !(session.user == null ||
          (Object.keys(session.user).length === 0 &&
            session.user.constructor === Object)),
    }),
    shallowEqual
  );

  return (
    <Switch>
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={SignInView}
        exact={true}
        layout={MainLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={SignUpView}
        exact={true}
        layout={MainLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={ForgotPassView}
        exact={true}
        layout={MainLayout}
        path="/forgot-pass"
      />
      <RouteWithLayout
        config={{ hasHeader: true, hasFooter: true, hasSubscribeNews: false }}
        component={ResetPassView}
        exact={true}
        layout={MainLayout}
        path="/reset-pass"
      />
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={ConfirmUserView}
        exact={true}
        layout={MainLayout}
        path="/confirm-user"
      />
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={TermsServiceView}
        exact={true}
        layout={MainLayout}
        path="/terms-service"
      />
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={NotFoundView}
        exact={true}
        layout={MainLayout}
        path="/not-found"
      />
      <RouteWithLayout
        config={{ hasHeader: false, hasFooter: false, hasSubscribeNews: false }}
        component={LogOutSocial}
        exact={true}
        layout={MainLayout}
        path="/log-out-social"
      />
      <MainRoutes />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default RootRoutes;
