import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { AiOutlineSearch } from "react-icons/ai";
import { Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  getCountries,
  getCuisineList,
  getGroceryTypes,
  getIngredientList,
  getVegetarianTypeList,
  selectLanguage,
} from "../../../store/app";
import { SignEdit, CheckBox, Pagination } from "../../../components";
import { updateProfile } from "../../../store/session";
import { history } from "../../../store/history";
import PageTemplate from "../../../components/PageTemplate";
import { useStoryblok } from "../../../services/storyblokService";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Overlay from "../../../components/Overlay";

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 52,
    paddingLeft: 30,
    marginBottom: 20,
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    paddingLeft: 30,
  }),
};

const Wizard = () => {
  const dispatch = useDispatch();
  const SB_loginWizard1 = useStoryblok("login-wizard-p1");
  const SB_loginWizard2 = useStoryblok("login-wizard-p2");
  const SB_loginWizard3 = useStoryblok("login-wizard-p3");
  const SB_loginWizard4 = useStoryblok("login-wizard-p4");
  const SB_userInformation = useStoryblok("user-information");
  const SB_languages = useStoryblok("languages");

  const {
    countries,
    cuisines,
    vegetarianTypes,
    ingredientInfo,
    language,
    languages,
  } = useSelector((state) => state.app);

  const profile = useSelector((state) => state.session.profile);

  const [name, setName] = useState("");
  const [email, setEmail] = useState(profile ? profile.email : "");
  const [step, setStep] = useState(1);
  const [country, setCountry] = useState(null);
  const [birthDay, setBirthDay] = useState();
  const [gender, setGender] = useState("male");
  const [ingredientPage, setIngredientPage] = useState(1);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedVegetarianTypes, setSelectedVegetarianTypes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [keywords, setKeywords] = useState({
    cuisine: "",
    vegetarianType: "",
    ingredient: "",
  });
  const [search, setSearch] = useState({
    cuisine: "",
    vegetarianType: "",
    ingredient: "",
  });
  const [availableLangs, setAvailableLangs] = useState([]);
  const [selectedLang, setSelectedLang] = useState();
  const langId = profile?.langId ?? 1;

  const count = 10;
  useEffect(() => {
    if (SB_languages?.content?.Language.length > 0) {
      let aux = [];
      SB_languages?.content?.Language.forEach((element) => {
        if (element.Enabled)
          aux.push({ ...element, value: element.Id, label: element.Name });
      });
      setAvailableLangs(aux);
      setSelectedLang(aux.find((l) => l.Id === language));
    }
  }, [SB_languages, language]);

  // The profile.email should be checked if it exists and if it does it should fill the email field with the value.



  useEffect(() => {
    if (profile && profile.email) {
      console.log(`Setting the email: ${profile.email}`);
      setEmail(profile.email);
    }
  }, [profile]);

  useEffect(() => {
    dispatch(getGroceryTypes());
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getCountries());
  }, [language]);

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handleContinue = (val) => {
    if (val === 2) {
      if (!name || !country || !birthDay) {
        return;
      }
    }

    setStep(val);
  };

  const handleCuisineCheck = (id) => {
    let tmpCuisines = selectedCuisines.slice();
    if (!tmpCuisines.includes(id)) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setSelectedCuisines(tmpCuisines);
  };

  const handleVegetarianCheck = (id) => {
    if (!selectedVegetarianTypes.includes(id)) {
      setSelectedVegetarianTypes( [id]);
    } else {
      setSelectedVegetarianTypes([]);
    }
  };

  const handleIngredientCheck = (e, code) => {
    let tmpIngredients = ingredients.slice();
    if (!tmpIngredients.includes(code)) {
      if (tmpIngredients.length > 4) {
        e.preventDefault();

        return;
      }

      tmpIngredients.push(code);
    } else {
      const index = tmpIngredients.indexOf(code);
      tmpIngredients.splice(index, 1);
    }

    setIngredients(tmpIngredients);
  };

  const handlePageChange = (val) => {
    setIngredientPage(val);

    dispatch(getIngredientList(val, count, "code", "asc", keywords.ingredient));
  };

  const handleFinish = () => {
    console.log("Finishing the registration wizard.");
    let updatedprofile = {};
    updatedprofile.name = name;
    updatedprofile.email = email;
    updatedprofile.country = country.value;
    updatedprofile.birthday = birthDay;
    updatedprofile.gender = gender;
    updatedprofile.cuisineList = selectedCuisines;
    updatedprofile.vegetarianTypeList = selectedVegetarianTypes;
    updatedprofile.unlikeList = ingredients;
    updatedprofile.userGroupId = 1;

    console.log(`Updated profile: ${JSON.stringify(updatedprofile)}`);
    dispatch(updateProfile(updatedprofile));

    history.push("/home");
  };

  const handleSearch = (type, e) => {
    if (type === "ingredient") {
      handlePageChange(1);
    } else {
      setSearch({ ...search, [type]: keywords[type] });
    }

    if (e) e.preventDefault();
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleLanguageChange = (val) => {
    dispatch(selectLanguage(val?.Id, true));
    if (profile) {
      for (const element in languages) {
        if (languages[element].code.toLowerCase() === val?.Id) {
          profile.langId = parseInt(element);
          //have to delete image because off error on backend
          delete profile.image;
          dispatch(updateProfile(profile));
        }
      }
    }
  };

  let countryOptions = [];
  for (const id in countries) {
    countryOptions.push({
      value: id,
      label: countries[id].name,
    });
  }

  const getPageTitle = () => {
    switch (step) {
      case 1:
        return SB_loginWizard1?.content?.Title;
      case 2:
        return SB_loginWizard2?.content?.Title;
      case 3:
        return SB_loginWizard3?.content?.Title;
      case 4:
        return SB_loginWizard4?.content?.Title;
    }
  };
  const getPageSubtitle = () => {
    switch (step) {
      case 1:
        return SB_loginWizard1?.content?.Mesg;
      case 2:
        return SB_loginWizard2?.content?.Mesg;
      case 3:
        return SB_loginWizard3?.content?.Mesg;
      case 4:
        return SB_loginWizard4?.content?.Mesg;
    }
  };

  if (
    !SB_languages ||
    !SB_loginWizard1 ||
    !SB_loginWizard2 ||
    !SB_loginWizard3 ||
    !SB_loginWizard4 ||
    !SB_userInformation
  ) {
    return <Overlay></Overlay>;
  }

  return (
    <PageTemplate
      backgroundImgSrc={MainImage}
      title={getPageTitle()}
      subtitle={getPageSubtitle()}
    >
      <div>
        <div className="preferences-wizard">
          {step === 1 ? (
            countryOptions ? (
              <div>
                <div
                  className="d-flex flex-column"
                  style={{
                    width: "80%",
                    margin: "auto",
                    padding: "30px 0",
                    gap: 20,
                  }}
                >
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Name}
                    </p>
                    <SignEdit
                      placeholder={SB_userInformation?.content?.Name}
                      style={{ margin: 0, width: "100%", marginBottom: 20 }}
                      value={name}
                      onChange={(val) => setName(val)}
                    />
                  </div>
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Email}
                    </p>
                    <SignEdit
                      placeholder="Email"
                      readOnly
                      style={{ margin: 0, width: "100%", marginBottom: 20 }}
                      value={email}
                      onChange={(val) => setEmail(val)}
                    />
                  </div>
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Country}
                    </p>
                    <Select
                      placeholder={SB_userInformation?.content?.Country}
                      options={countryOptions}
                      isSearchable={true}
                      styles={customStyles}
                      value={country}
                      onChange={(val) => setCountry(val)}
                    />
                  </div>
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Language}
                    </p>
                    <Select
                      placeholder={SB_userInformation?.content?.Language}
                      options={availableLangs}
                      isSearchable={true}
                      styles={customStyles}
                      value={selectedLang}
                      onChange={(val) => handleLanguageChange(val)}
                    />
                  </div>
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Birthday}
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        disableFuture
                        maxDate={dayjs().subtract(14, "year")}
                        value={birthDay}
                        onChange={(date, context) => {
                          if (!context.validationError) setBirthDay(date);
                          else setBirthDay(undefined);
                        }}
                        sx={{ width: "100%" }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    <p className="profile-subject">
                      *{SB_userInformation?.content?.Gender}
                    </p>
                    <RadioGroup
                      aria-label="gender"
                      row={true}
                      name="gender1"
                      value={gender}
                      onChange={handleChangeGender}
                    >
                      <FormControlLabel
                        value="male"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#FAA72D",
                              },
                            }}
                          />
                        }
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#FAA72D",
                              },
                            }}
                          />
                        }
                        label="Female"
                      />
                    </RadioGroup>
                  </div>
                  <Button
                    variant="contained"
                    disabled={!name || !country || !birthDay}
                    onClick={() => handleContinue(2)}
                    sx={{
                      "&.MuiButtonBase-root": {
                        backgroundColor: "#FAA72D",
                        fontSize: "19px",
                        fontWeight: "500",
                        textTransform: "unset",
                        borderRadius: "25px",
                        padding: "0 40px",
                        "&.Mui-disabled": {
                          backgroundColor: "gray",
                          color: "unset",
                          opacity: 0.5,
                        },
                      },
                    }}
                  >
                    {SB_loginWizard1?.content?.Continue}
                  </Button>
                </div>
              </div>
            ) : (
              <ClipLoader color="#FC9C52" loading={true} size={35} />
            )
          ) : step === 2 ? (
            <div className="d-flex flex-column">
              <div style={{ display: "flex", gap: 30 }}>
                <Button
                  variant="contained"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    minWidth: "unset",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#FC9C52",
                      svg: {
                        color: "white",
                        path: { color: "white" },
                      },
                    },
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowBackIosNewIcon
                    style={{
                      height: 14,
                      color: "#B8BABD",
                    }}
                  />
                </Button>
              </div>

              <div
                style={{
                  color: "#6B6C72",
                  fontSize: 14,
                  fontWeight: 400,
                  height: 250,
                  overflowY: "scroll",
                  padding: "0 15%",
                  marginTop: 10,
                }}
              >
                {cuisines ? (
                  Object.entries(cuisines).map((cuisine, index) =>
                    cuisine[1].name.includes(search.cuisine) ? (
                      <CheckBox
                        style={{ padding: "5px 0" }}
                        id={`cuisine_${cuisine[0]}`}
                        key={`cuisine_${index}`}
                        onClick={() => handleCuisineCheck(parseInt(cuisine[0]))}
                      >
                        {cuisine[1].name}
                      </CheckBox>
                    ) : null
                  )
                ) : (
                  <div className="HV-center h-100">
                    <ClipLoader color="#FC9C52" loading={true} size={35} />
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                onClick={() => handleContinue(3)}
                sx={{
                  "&.MuiButtonBase-root": {
                    backgroundColor: "#FAA72D",
                    fontSize: "19px",
                    fontWeight: "500",
                    textTransform: "unset",
                    borderRadius: "25px",
                    padding: "0 40px",
                    margin: "20px auto 0 auto",
                    maxWidth: "50%",
                  },
                }}
              >
                {SB_loginWizard2?.content?.Continue}
              </Button>
            </div>
          ) : step === 3 ? (
            <div className="d-flex flex-column">
              <div style={{ display: "flex", gap: 30 }}>
                <Button
                  variant="contained"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    minWidth: "unset",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#FC9C52",
                      svg: {
                        color: "white",
                        path: { color: "white" },
                      },
                    },
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowBackIosNewIcon
                    style={{
                      height: 14,
                      color: "#B8BABD",
                    }}
                  />
                </Button>
              </div>
              <div
                style={{
                  color: "#6B6C72",
                  fontSize: 14,
                  fontWeight: 400,
                  height: 250,
                  overflowY: "scroll",
                  padding: "0 15%",
                  marginTop: 10,
                }}
              >
                {vegetarianTypes ? (
                  Object.entries(vegetarianTypes).map((vegetarianType, index) =>(
                    vegetarianType[1].name.includes(search.vegetarianType) ? (
                      <CheckBox
                        style={{ padding: "5px 0" }}
                        id={`vegetarianType_${vegetarianType[0]}`}
                        key={`vegetarianType_${index}`}
                        checked={selectedVegetarianTypes.includes(parseInt(vegetarianType[0]))}
                        onClick={() =>
                          handleVegetarianCheck(parseInt(vegetarianType[0]))
                        }
                      >
                        {vegetarianType[1].name}
                      </CheckBox>
                    ) : null
                  ))
                ) : (
                  <div className="HV-center h-100">
                    <ClipLoader color="#FC9C52" loading={true} size={35} />
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                onClick={() => handleContinue(4)}
                sx={{
                  "&.MuiButtonBase-root": {
                    backgroundColor: "#FAA72D",
                    fontSize: "19px",
                    fontWeight: "500",
                    textTransform: "unset",
                    borderRadius: "25px",
                    padding: "0 40px",
                    margin: "20px auto 0 auto",
                    maxWidth: "50%",
                  },
                }}
              >
                {SB_loginWizard3?.content?.Continue}
              </Button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div style={{ display: "flex", gap: 30 }}>
                <Button
                  variant="contained"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 50,
                    minWidth: "unset",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#FC9C52",
                      svg: {
                        color: "white",
                        path: { color: "white" },
                      },
                    },
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowBackIosNewIcon
                    style={{
                      height: 14,
                      color: "#B8BABD",
                    }}
                  />
                </Button>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <form
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#F5F8FE",
                    borderRadius: 6,
                    height: 40,
                    paddingRight: 20,
                  }}
                  onSubmit={(e) => handleSearch("ingredient", e)}
                >
                  <input
                    placeholder="Search"
                    style={{
                      border: "none",
                      outline: "none",
                      paddingLeft: 20,
                      borderRadius: 6,
                      fontSize: 16,
                      width: "100%",
                      height: "100%",
                      color: "#07122E",
                      backgroundColor: "#F5F8FE",
                    }}
                    value={keywords.ingredient}
                    onChange={(val) =>
                      setKeywords({ ...keywords, ingredient: val.target.value })
                    }
                  />
                  <AiOutlineSearch
                    className="pointer"
                    style={{ fontSize: 18, marginRight: 5 }}
                    onClick={() => handleSearch("ingredient")}
                  />
                </form>
              </div>
              <div
                style={{
                  color: "#6B6C72",
                  fontSize: 14,
                  fontWeight: 400,
                  height: 250,
                  overflowY: "scroll",
                  padding: "0 15%",
                  marginTop: 10,
                }}
              >
                {ingredientInfo && ingredientInfo.ingredients ? (
                  ingredientInfo.ingredients.map((ingredient) => {
                    return (
                      <CheckBox
                        style={{ padding: "5px 0" }}
                        id={`ingredient_${ingredient.code}`}
                        key={`ingredient${ingredient.id}`}
                        onClick={(e) =>
                          handleIngredientCheck(e, ingredient.code)
                        }
                      >
                        {ingredient.friendlyDescription[langId]}
                      </CheckBox>
                    );
                  })
                ) : (
                  <div className="HV-center h-100">
                    <ClipLoader color="#FC9C52" loading={true} size={35} />
                  </div>
                )}
              </div>
              <div style={{ margin: "30px auto 0 auto" }}>
                <Pagination
                  page={ingredientPage ?? 1}
                  numPages={ingredientInfo?.pages}
                  onChangePage={(e, page) => handlePageChange(page)}
                />
              </div>
              <Button
                variant="contained"
                onClick={() => handleFinish()}
                sx={{
                  "&.MuiButtonBase-root": {
                    backgroundColor: "#FAA72D",
                    fontSize: "19px",
                    fontWeight: "500",
                    textTransform: "unset",
                    borderRadius: "25px",
                    padding: "0 40px",
                    margin: "20px auto 0 auto",
                    maxWidth: "50%",
                  },
                }}
              >
                {SB_loginWizard4?.content?.Finish}
              </Button>
            </div>
          )}
        </div>
      </div>
    </PageTemplate>
  );
};

export default Wizard;
