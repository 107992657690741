import { toastr } from "react-redux-toastr";

export function toggleTheme(name) {
  detachStylesheets();
  insertStylesheet(name);
}

function detachStylesheets() {
  Array.from(document.querySelectorAll('link[rel="stylesheet"]')).forEach(
    (style) => {
      style.parentNode.removeChild(style);
    }
  );
}

function insertStylesheet(name) {
  var link = document.createElement("link");
  link.href = "/css/" + name + ".css";
  link.type = "text/css";
  link.rel = "stylesheet";
  document.getElementsByTagName("head")[0].appendChild(link);
}

export function showToastr(type, title, message, options) {
  const config = {
    timeOut: 3000,
    showCloseButton: true,
    progressBar: false,
    position: "bottom-center",
    ...options,
  };

  const toastrInstance =
    type === "info"
      ? toastr.info
      : type === "warning"
      ? toastr.warning
      : type === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(title, message, config);
}
