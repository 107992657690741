import * as types from "./actionTypes";

const initialState = {
  web_menu: null,
  languages: null,
  banners: null,
  logo: null,
  aboutUs: null,
  logoutMsg: null,
  articlesSection: null,
  articles: null,
  howItWorks: null,
};

const storyBlok = (state = initialState, action) => {
  switch (action.type) {
    case types.WEB_MENU:
      return { ...state, web_menu: action.web_menu };
    case types.LANGUAGES:
      return { ...state, languages: action.languages };
    case types.BANNERS:
      return { ...state, banners: action.banners };
    case types.LOGO:
      return { ...state, logo: action.logo };
    case types.ABOUTUS:
      return { ...state, aboutUs: action.aboutUs };
    case types.LOGOUTMSG:
      return { ...state, logoutMsg: action.logoutMsg };
    case types.ARTICLESSECTION:
      return { ...state, articlesSection: action.articlesSection };
    case types.ARTICLES:
      return { ...state, articles: action.articles };
    case types.HOWITWORKS:
      return { ...state, howItWorks: action.howItWorks };
    case types.CLEAN:
      //dont clean
      const logo = state.logo;
      const languages = state.languages;
      return { ...initialState, logo: logo, languages: languages };

    default:
      return state;
  }
};

export default storyBlok;
