import React from 'react';


const CheckBox = (props) => {

  const { id, style, children, checked, onClick } = props;

  const getClass = () => {
    return "checkbox" + (checked ? " isChecked" : "");
  }

  return (
    <div className="d-flex align-items-center" style={style}>
      <input 
        className={getClass()}
        type="checkbox"
        id={id} 
        value={checked}
        checked={checked}
        style={{ marginRight: 10, padding: 5, minWidth: 18, }} 
        onClick={onClick}
      />
      <label htmlFor={id} style={{ margin: 0 }}>
        {children}
      </label>
    </div>
  );
}

export default CheckBox;