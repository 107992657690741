import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FaBookmark } from "react-icons/fa";
import { FiBookmark } from "react-icons/fi";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { updatePrefer } from "../../store/app/actions";
import FoodImage1 from "../../assets/image/tmp/food-1.png";
import VotingMealDialog from "../VotingMealDialog";
import { languageMapping } from "../../utils/languageMapping";


const RecipeItem = ({
  recipe,
  SB_recipeMultilingueFields,
  showFavIcon,
  clickVoting,
}) => {
  const dispatch = useDispatch();

  const { fields, language } = useSelector((state) => state.app);
  const voteInfo = recipe.votes.comments.find((vote) => vote.isOwn);

  const [preference, setPreference] = useState(recipe.preferred);
  const [openVoting, setOpenVoting] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const languageIndex = languageMapping(language);

  useEffect(() => {
    setPreference(recipe.preferred);
  }, [recipe]);

  const handlePreference = () => {
    setPreference(!preference);
    dispatch(updatePrefer(recipe.id, !preference));
  };

  const handleVoting = () => {
    setOpenVoting(true);
  };

  const handleClose = () => {
    setOpenVoting(false);
  };

  const getMacroValue = (macro) => {
    return recipe?.quantityPerPerson
      ?.find(
        (quantity) =>
          quantity.fieldId ===
          +Object.entries(fields).find((field) => field[1].display === macro)[0]
      )
      .value.toFixed(1);
  };

  if (!fields) {
    return;
  }

  return (
    <>
      <div
        style={{
          textAlign: "center",
          margin: "10px 0",
          position: "relative",
        }}
      >
        <div style={{ position: "relative" }}>
          <Link
            to={{
              pathname: `/vegetarian-food/detail/${recipe.id}`,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${recipe.image ?? FoodImage1})`,
                width: "100%",
                paddingTop: "54.4%",
                backgroundSize: "100% 100%",
                borderRadius: "8px 8px 0px 0px",
              }}
            />
          </Link>
          <div
            className="pointer"
            onClick={clickVoting ? handleVoting : undefined}
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              position: "absolute",
              bottom: "5px",
              right: "10px",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              padding: "1px 5px",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#6B6C72",
                fontWeight: "400",
              }}
            >
              {recipe?.votes?.voteStar}
            </p>
            {voteInfo ? (
              <AiFillStar
                className="pointer"
                style={{
                  fontSize: 11,
                  color: "#FAA72D",
                }}
              />
            ) : (
              <AiOutlineStar
                className="pointer"
                style={{
                  fontSize: 11,
                  color: "#FAA72D",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            padding: "5px 10% 0 10%",
            borderRadius: "0 0 28px 28px",
            boxShadow: "0 8px 10px rgb(0 0 0 / 20%)",
            backgroundColor: "white",
          }}
        >
          <Tooltip
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive
            title={recipe.title[languageIndex]}
            placement="top"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                onMouseOver={() => {
                  setTooltipOpen(true);
                }}
                onMouseLeave={() => {
                  setTooltipOpen(false);
                }}
                style={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 400,
                  textAlign: showFavIcon ? "left" : "center",
                  fontFamily: "Roboto",
                  display: "inline-block",
                  width: showFavIcon ? "80%" : "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {recipe.title[languageIndex]}
              </p>
              {showFavIcon ? (
                preference ? (
                  <FaBookmark
                    className="pointer"
                    style={{ fontSize: 22, color: "orange", float: "right" }}
                    onClick={handlePreference}
                  />
                ) : (
                  <FiBookmark
                    className="pointer"
                    style={{ fontSize: 22, color: "orange", float: "right" }}
                    onClick={handlePreference}
                  />
                )
              ) : null}
            </div>
          </Tooltip>
          {/* <div style={{ textAlign: "left" }}>
            <p
              style={{
                fontSize: 16,
                color: "black",
                fontWeight: 400,
                textAlign: "left",
                fontFamily: "Roboto",
                display: "inline-block",
                width: "80%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {recipe.title[language]}
            </p>
            {preference ? (
              <FaBookmark
                className="pointer"
                style={{ fontSize: 22, color: "orange", float: "right" }}
                onClick={handlePreference}
              />
            ) : (
              <FiBookmark
                className="pointer"
                style={{ fontSize: 22, color: "orange", float: "right" }}
                onClick={handlePreference}
              />
            )}
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
              color: "black",
              paddingBottom: "10%",
              paddingTop: "5%",
            }}
          >
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Calorie}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Energy")}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Protein}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Protein")}
              </p>
            </div>
            <div>
              <p
                style={{
                  color: "rgb(249, 147, 69)",
                  fontSize: 12,
                  fontWeight: 700,
                  marginBottom: 0,
                  fontFamily: "Roboto",
                }}
              >
                {SB_recipeMultilingueFields?.content?.Fat}
              </p>
              <p
                className="text-center"
                style={{ fontSize: 12, color: "#6B6C72", fontWeight: 600 }}
              >
                {getMacroValue("Fat")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <VotingMealDialog
        openDialog={openVoting}
        onCloseHandler={() => handleClose()}
        title={SB_recipeMultilingueFields?.content?.Vote_Title}
        recipe={recipe}
      ></VotingMealDialog>
    </>
  );
};

export default RecipeItem;
