import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import { BsArrowLeft } from "react-icons/bs";
import BeatLoader from "react-spinners/BeatLoader";

import { getRecipe } from "../../../store/app/actions";
import { history } from "../../../store/history";
import FoodImage1 from "../../../assets/image/tmp/food-1.png";
import PageTemplate from "../../../components/PageTemplate";
import { useStoryblok } from "../../../services/storyblokService";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import styles from "./Votes.module.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";

const Votes = (props) => {
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");

  const location = useLocation();
  const params = useParams();
  const id = params.id;

  const dispatch = useDispatch();

  const preferences = useSelector((state) => state.app.preferences);
  const recipeInfo = useSelector((state) => state.app.recipeInfo);
  const inRecipe = useSelector((state) => state.app.recipe);
  const profile = useSelector((state) => state.session.profile);

  useEffect(() => {
    if (
      !recipeInfo ||
      (recipeInfo &&
        recipeInfo.meals.find((recipe) => recipe.id === id) === undefined &&
        preferences &&
        preferences.meals.find((recipe) => recipe.id === id) === undefined)
    ) {
      dispatch(getRecipe(id));
    }
  }, []);

  let meal;
  if (recipeInfo) meal = recipeInfo.meals.find((recipe) => recipe.id === id);
  if (!meal && preferences)
    meal = preferences.meals.find((recipe) => recipe.id === id);
  if (!meal) meal = inRecipe;

  const recipe = meal;

  const language = profile?.langId ?? 1;

  if (!recipe) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: 30,
          height: 30,
          borderRadius: 50,
          minWidth: "unset",
          backgroundColor: "white",
          position: "absolute",
          left: "80px",
          zIndex: 1,
        }}
        onClick={() => history.goBack()}
      >
        <ArrowBackIosNewIcon
          style={{
            height: 14,
            color: "#B8BABD",
          }}
        />
      </Button>
      <PageTemplate backgroundImgSrc={recipe.image}>
        <img
          style={{ width: "100%", height: "350px", objectFit: "cover" }}
          src={recipe.image ?? FoodImage1}
          className="rounded"
          alt="Placeholder"
        />

        <div className={styles.votesContentWrapper}>
          <div className={styles.votesTitleRatingWrapper}>
            <div className={styles.votesTitleWrapper}>
              <h1>{inRecipe?.title[language]}</h1>
              <p>
                {SB_recipeMultilingueFields?.content?.SubmittedBy}:{" "}
                {inRecipe?.submittedBy}
              </p>
            </div>
            <div className={styles.votesRatingWrapper}>
              <Rating
                emptySymbol={
                  <FiStar
                    style={{
                      color: "#FC9C52",
                      width: 20,
                      height: 20,
                      margin: "0 4px",
                    }}
                  />
                }
                fullSymbol={
                  <FaStar
                    style={{
                      color: "#FC9C52",
                      width: 20,
                      height: 20,
                      margin: "0 4px",
                    }}
                  />
                }
                initialRating={recipe.votes.voteStar}
                readonly
              />
              <span>{inRecipe?.votes?.voteStar}</span>
              <span>{`(${inRecipe.votes.voteCount} ratings)`}</span>
            </div>
          </div>

          <div className={styles.votesCardsWrapper}>
            {recipe.votes.comments.map((vote, index) => (
              <div className={styles.votesCardContainer} key={index}>
                <div className={styles.votesCardHeader}>
                  <p>{vote.userName}</p>
                  <Rating
                    emptySymbol={
                      <FiStar
                        style={{
                          color: "#FC9C52",
                          width: 20,
                          height: 20,
                          margin: "0 4px",
                        }}
                      />
                    }
                    fullSymbol={
                      <FaStar
                        style={{
                          color: "#FC9C52",
                          width: 20,
                          height: 20,
                          margin: "0 4px",
                        }}
                      />
                    }
                    initialRating={vote.star}
                    readonly
                  />
                </div>
                <p>{vote.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default Votes;
