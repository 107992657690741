  import * as types from "./actionTypes";
import { createDebouncedAction } from "../../utils/debouncer";
import { ApiService } from "../../services/apiService";
import { clean } from "../storyblok/actions";
import { logoutUser } from "../session";
import { showToastr } from "../../services/themeService";

export const getCountries = () => {
  return (dispatch) => {
    dispatch(countryLoading());

    ApiService.getCountries()
      .then((data) => {
        console.log("countries: ", data);
        dispatch(countries(data));
      })
      .catch((error) => {
        console.log("countries error: ", error);
        dispatch(countryError(error.message));
      });
  };
};

export const getCuisineList = () => {
  return (dispatch) => {
    dispatch(cuisineLoading());

    ApiService.getCuisines()
      .then((data) => {
        console.log("cuisines: ", data);
        dispatch(cuisines(data));
      })
      .catch((error) => {
        console.log("cuisines error: ", error);
        dispatch(cuisineError(error.message));
      });
  };
};

export const getVegetarianTypeList = () => {
  return (dispatch) => {
    dispatch(vegetarianLoading());

    ApiService.getVegetarianTypes()
      .then((data) => {
        console.log("vegetarianTypes: ", data);
        dispatch(vegetarianTypes(data));
      })
      .catch((error) => {
        console.log("vegetarianTypes error: ", error);
        dispatch(vegetarianError(error.message));
      });
  };
};

export const getIngredientList = (
  page,
  count,
  fieldName,
  direction,
  keyword
) => {
  return (dispatch) => {
    dispatch(ingredientLoading());

    ApiService.getIngredients(page, count, fieldName, direction, keyword)
      .then((data) => {
        dispatch(ingredientInfo(data));
      })
      .catch((error) => {
        console.log("ingredients error: ", error);
        if (error.response?.status === 401) {
          //showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(logoutUser());

          return;
        }

        dispatch(recipesError(error.message));
        dispatch(ingredientError(error.message));
      });
  };
};

// export const getContentList = () => {
//   return (dispatch) => {
//     dispatch(contentLoading());

//     ApiService.getContents()
//       .then((data) => {
//         debugger
//         console.log("contents: ", data);
//         dispatch(contentList(data));
//       })
//       .catch((error) => {
//         console.log("contents error: ", error);
//         dispatch(contentError(error.message));
//       });
//   };
// };

export const getRecommendations = () => {
  return (dispatch) => {
    ApiService.getRecommendations()
      .then((data) => {
        console.log("recommendations: ", data);
        dispatch(recommendationsSuccess(data));
      })
      .catch((error) => {
        console.log("recommendations error: ", error);
        dispatch(recommendationsError(error.message));
      });
  };
};

export const noneRecipeList = () => {
  return (dispatch) => {
    dispatch(recipesInfo(0, null));
  };
};

export const getRecipeList = (page, count, sortBy, filterOptions) => {
  return (dispatch) => {


    console.log("filterOptions", filterOptions);
    let filter = {};

    if (filterOptions.cuisineList.length > 0)
      filter.cuisineList = filterOptions.cuisineList;
    if (filterOptions.vegetarianTypeList.length > 0)
      filter.vegetarianTypeList = filterOptions.vegetarianTypeList;
    if (filterOptions.difficultyLevelList.length > 0)
      filter.difficultyLevelList = filterOptions.difficultyLevelList;
    if (filterOptions.evaluationStar.length > 0)
      filter.evaluationStar = { ranges: filterOptions.evaluationStar };

    filter.keyword = filterOptions.keyword;

    console.log("filter", filter);

    dispatch(recipesLoading());

    ApiService.getRecipes(page, count, sortBy, filter)
      .then((data) => {
        console.log("recipes: ", data);
        dispatch(recipesInfo(data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(recipesError(error.message));
      });
  };
};

export const getRecipe = (id) => {
  return (dispatch) => {
    dispatch(recipeLoading());

    ApiService.getRecipe(id)
      .then((data) => {
        console.log("recipe: ", data);
        dispatch(recipeSuccess(data));
      })
      .catch((error) => {
        console.log("recipe error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(recipeError(error.message));
      });
  };
};

export const getFieldList = () => {
  return (dispatch) => {
    dispatch(fieldLoading());

    ApiService.getFields()
      .then((data) => {
        console.log("fields: ", data);
        dispatch(fieldSuccess(data));
      })
      .catch((error) => {
        console.log("fields error: ", error);
        dispatch(fieldError(error.message));
      });
  };
};

export const getPreferenceList = (page, count, sortField, sortBy) => {
  return (dispatch) => {

    dispatch(preferenceLoading());

    ApiService.getPrefers(page, count, sortField, sortBy)
      .then((data) => {
        console.log("preferences", data);
        dispatch(preferences(data));
      })
      .catch((error) => {
        console.log("preferences error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(preferenceError(error.message));
      });
  };
};

export const updateVoting = (id, star, comment) => {
  return (dispatch, getState) => {
    const { app } = getState();

    dispatch(votingLoading());

    ApiService.updateVoting(id, star, comment)
      .then((data) => {
        let tmpRecipeInfo = JSON.parse(JSON.stringify(app.recipeInfo));
        let recipe;
        if (tmpRecipeInfo) {
          recipe = tmpRecipeInfo?.meals?.find((item) => item.id === id);
          const index = tmpRecipeInfo.meals.indexOf(recipe);
          tmpRecipeInfo.meals[index] = data;
          dispatch(recipesInfo(tmpRecipeInfo));
        }
        let tmpPreferences = JSON.parse(JSON.stringify(app.preferences));
        if (tmpPreferences) {
          recipe = tmpPreferences?.meals?.find((item) => item.id === id);
          const index = tmpPreferences.meals.indexOf(recipe);
          tmpPreferences.meals[index] = data;
          dispatch(preferences(tmpPreferences));
        }
        if (recipe === undefined) {
          dispatch(recipeSuccess(data));
        }

        showToastr("success", "Vote", "Voting Updated.");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(votingError(error.message));
      });
  };
};

export const clearPrefer = () => {
  return (dispatch) => {
    dispatch(preferences(null));
  };
};

export const clearWeeklyPlan = () => {
  return (dispatch) => {
    dispatch(weeklyPlanSuccess([]));
  };
};

export const updatePrefer = (id, prefer) => {
  return (dispatch, getState) => {
    const { app } = getState();

    dispatch(preferLoading());

    ApiService.updatePrefer(id, prefer)
      .then((data) => {
        console.log("update prefer: ", data);

        dispatch(preferSuccess());

        //dunno why this is needed
        let tmpRecipeInfo = JSON.parse(JSON.stringify(app.recipeInfo));
        let recipe = tmpRecipeInfo.meals.find((item) => item.id === id);
        if (recipe === undefined) {
          dispatch(recipeSuccess(data));
        } else {
          const index = tmpRecipeInfo.meals.indexOf(recipe);
          tmpRecipeInfo.meals[index] = data;
          dispatch(recipesInfo(tmpRecipeInfo));
        }

        dispatch(getPreferenceList(1, 12, "id", "asc"));

        showToastr("success", "Prefer", "Prefer Updated.");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(preferError(error.message));
      });
  };
};

export const getDifficultyLevelList = () => {
  return (dispatch) => {
    dispatch(difficultyLoading());

    ApiService.getDifficultyLevels()
      .then((data) => {
        console.log("difficultyLevels: ", data);
        dispatch(difficultyList(data));
      })
      .catch((error) => {
        console.log("difficultyLevels error: ", error);
        dispatch(difficultyError(error.message));
      });
  };
};

export const selectLanguage = createDebouncedAction(
  (lang, cleanStoryblokStore = false) => (dispatch) => {
    dispatch(languageSelected(lang));
    if (cleanStoryblokStore) dispatch(clean());
  },
  300
);

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(languagesLoading());

    ApiService.getLanguages()
      .then((data) => {
        console.log("languages: ", data);
        dispatch(languagesSuccess(data));
      })
      .catch((error) => {
        console.log("languages error: ", error);
        dispatch(languagesError(error.message));
      });
  };
};

export const getRDDEvaluations = () => {
  return (dispatch) => {
    dispatch(rddEvaluationsLoading());

    ApiService.getRDDEvaluations()
      .then((data) => {
        console.log("rddEvaluations: ", data);
        dispatch(rddEvaluationsSuccess(data));
      })
      .catch((error) => {
        console.log("rddEvaluations error: ", error);
        dispatch(rddEvaluationsError(error.message));
      });
  };
}

export const getMealLevels = () => {
  return (dispatch) => {
    dispatch(mealLevelsLoading());

    ApiService.getMealLevels()
      .then((data) => {
        console.log("mealLevels: ", data);
        dispatch(mealLevelsSuccess(data));
      })
      .catch((error) => {
        console.log("mealLevels error: ", error);
        dispatch(mealLevelsError(error.message));
      });
  };
};

export const addWeeklyPlan = (id, date, mealTime, amount) => {
  return (dispatch) => {
    dispatch(weeklyPlanLoading());

    ApiService.addWeeklyPlan(id, date, mealTime, amount)
      .then((data) => {
        if (data.errorMessage) {
          dispatch(weeklyPlanError(data.errorMessage));

          return;
        }
        console.log("weeklyPlan: ", data);
        dispatch(weeklyPlanSuccess(data));
      })
      .catch((error) => {
        console.log("weeklyPlan error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(weeklyPlanError(error.message));
      });
  };
};

export const getWeeklyPlan = (date) => {
  return (dispatch) => {
    dispatch(weeklyPlanLoading());

    ApiService.getWeeklyPlan(date)
      .then((data) => {
        console.log("weeklyPlan: ", data);
        dispatch(weeklyPlanSuccess(data));
      })
      .catch((error) => {
        console.log("weeklyPlan error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(weeklyPlanError(error.message));
      });
  };
};

export const deleteWeeklyPlan = (id, date, mealTime) => {
  return (dispatch) => {
    dispatch(weeklyPlanLoading());

    ApiService.deleteWeeklyPlan(id, date, mealTime)
      .then((data) => {
        console.log("weeklyPlan: ", data);
        dispatch(weeklyPlanSuccess(data));
      })
      .catch((error) => {
        console.log("weeklyPlan error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(weeklyPlanError(error.message));
      });
  };
};

export const updateWeeklyPlan = (id, date, mealTime, amount) => {
  return (dispatch) => {
    dispatch(weeklyPlanLoading());

    ApiService.updateWeeklyPlan(id, date, mealTime, amount)
      .then((data) => {
        console.log("weeklyPlan: ", data);
        dispatch(weeklyPlanSuccess(data));
      })
      .catch((error) => {
        console.log("weeklyPlan error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(weeklyPlanError(error.message));
      });
  };
};

export const updateWeeklyPlanList = (date, mealTime, meals) => {
  return (dispatch) => {
    dispatch(weeklyPlanLoading());

    ApiService.updateWeeklyPlanList(date, mealTime, meals)
      .then((data) => {
        console.log("weeklyPlan: ", data);
        dispatch(weeklyPlanSuccess(data));
      })
      .catch((error) => {
        console.log("weeklyPlan error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(weeklyPlanError(error.message));
      });
  };
};

export const getGroceries = (meals) => {
  return (dispatch) => {
    dispatch(groceriesLoading());

    ApiService.getGroceries(meals)
      .then((data) => {
        console.log("groceries: ", data);
        if (data.errorType || data.errorMessage) {
          throw new Error(data.errorMessage);
        }
        dispatch(groceriesSuccess(data));
      })
      .catch((error) => {
        console.log("groceries error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(groceriesError(error.message));
      });
  };
};

export const getGroceryTypes = (lang) => {
  return (dispatch) => {
    dispatch(groceryTypesLoading());

    ApiService.getGroceryTypes(lang)
      .then((data) => {
        console.log("groceryTypes: ", data);
        dispatch(groceryTypesSuccess(data));
      })
      .catch((error) => {
        console.log("groceryTypes error: ", error);
        if (error.response?.status === 401) {
          showToastr(
            "warning",
            "Authorization Error",
            error.response.data.message
          );
          dispatch(logoutUser());

          return;
        }

        dispatch(groceryTypesError(error.message));
      });
  };
};

const countryLoading = () => ({
  type: types.COUNTRY_LOADING,
});

const countries = (data) => ({
  type: types.COUNTRY_SUCCESS,
  countries: data,
});

const countryError = (error) => ({
  type: types.COUNTRY_ERROR,
  error,
});

const cuisineLoading = () => ({
  type: types.CUISINE_LOADING,
});

const cuisines = (data) => ({
  type: types.CUISINE_SUCCESS,
  cuisines: data,
});

const cuisineError = (error) => ({
  type: types.CUISINE_ERROR,
  error,
});

const vegetarianLoading = () => ({
  type: types.VEGETARIAN_LOADING,
});

const vegetarianTypes = (data) => ({
  type: types.VEGETARIAN_SUCCESS,
  vegetarianTypes: data,
});

const vegetarianError = (error) => ({
  type: types.VEGETARIAN_ERROR,
  error,
});

const ingredientLoading = () => ({
  type: types.INGREDIENT_LOADING,
});

const ingredientInfo = (data) => ({
  type: types.INGREDIENT_SUCCESS,
  ingredientInfo: data,
});

const ingredientError = (error) => ({
  type: types.INGREDIENT_ERROR,
  error,
});

const recommendationsSuccess = (data) => ({
  type: types.RECOMMENDATION_SUCCESS,
  recommendations: data,
});

const recommendationsError = (error) => ({
  type: types.RECOMMENDATION_ERROR,
  error,
});

const recipesLoading = () => ({
  type: types.RECIPES_LOADING,
});

const recipesInfo = (data) => ({
  type: types.RECIPES_SUCCESS,
  recipeInfo: data,
});

const recipesError = (error) => ({
  type: types.RECIPES_ERROR,
  error,
});

const recipeLoading = () => ({
  type: types.RECIPE_LOADING,
});

const recipeSuccess = (data) => ({
  type: types.RECIPE_SUCCESS,
  recipe: data,
});

const recipeError = (error) => ({
  type: types.RECIPE_ERROR,
  error,
});

const preferenceLoading = () => ({
  type: types.PREFERENCE_LOADING,
});

const preferences = (data) => ({
  type: types.PREFERENCE_SUCCESS,
  preferences: data,
});

const preferenceError = (error) => ({
  type: types.PREFERENCE_ERROR,
  error,
});

const votingLoading = () => ({
  type: types.VOTING_LOADING,
});

const votingError = (error) => ({
  type: types.VOTING_ERROR,
  error,
});

const preferLoading = () => ({
  type: types.VOTING_LOADING,
});

const preferSuccess = () => ({
  type: types.VOTING_SUCCESS,
});

const preferError = (error) => ({
  type: types.VOTING_ERROR,
  error,
});

const difficultyLoading = () => ({
  type: types.DIFFICULTY_LOADING,
});

const difficultyList = (data) => ({
  type: types.DIFFICULTY_SUCCESS,
  difficultyList: data,
});

const difficultyError = (error) => ({
  type: types.DIFFICULTY_ERROR,
  error,
});

const fieldLoading = () => ({
  type: types.FIELD_LOADING,
});

const fieldSuccess = (data) => ({
  type: types.FIELD_SUCCESS,
  fields: data,
});

const fieldError = (error) => ({
  type: types.FIELD_ERROR,
  error,
});

const languagesLoading = () => ({
  type: types.LANGUAGES_LOADING,
});

const languagesSuccess = (data) => ({
  type: types.LANGUAGES_SUCCESS,
  languages: data,
});

const languagesError = (error) => ({
  type: types.LANGUAGES_ERROR,
  error,
});

const rddEvaluationsLoading = () => ({
  type: types.RDD_EVAL_LOADING
})

const rddEvaluationsSuccess = (data) => ({
  type: types.RDD_EVAL_SUCCESS,
  rddEvaluations: data
})

const rddEvaluationsError = (error) => ({
  type: types.RDD_EVAL_ERROR,
  error,
})

const languageSelected = createDebouncedAction(
  (data) => ({
  type: types.LANGUAGE_SELECTED,
  language: data,
}),
200
);

const mealLevelsLoading = () => ({
  type: types.MEALLEVELS_LOADING,
});

const mealLevelsSuccess = (data) => ({
  type: types.MEALLEVELS_SUCCESS,
  mealLevels: data,
});

const mealLevelsError = (error) => ({
  type: types.MEALLEVELS_ERROR,
  error,
});

const weeklyPlanLoading = () => ({
  type: types.WEEKLY_LOADING,
});

const weeklyPlanSuccess = (data) => ({
  type: types.WEEKLY_SUCCESS,
  weeklyPlans: data,
});

const weeklyPlanError = (error) => ({
  type: types.WEEKLY_ERROR,
  error,
});

const groceriesLoading = () => ({
  type: types.GROCERIES_LOADING,
});

const groceriesSuccess = (data) => ({
  type: types.GROCERIES_SUCCESS,
  groceries: data,
});

const groceriesError = (error) => ({
  type: types.GROCERIES_ERROR,
  error,
});

const groceryTypesLoading = () => ({
  type: types.GROCERY_TYPES_LOADING,
});

const groceryTypesSuccess = (data) => ({
  type: types.GROCERY_TYPES_SUCCESS,
  groceryTypes: data,
});

const groceryTypesError = (error) => ({
  type: types.GROCERY_TYPES_ERROR,
  error,
});
