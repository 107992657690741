import React from "react";

import { Button } from "../index";

import { useStoryblok } from "../../services/storyblokService";

export default function SubscribeBar(props) {
  const storyPlanNewsletter = useStoryblok("subscribe-newsletter");

  return (
    <div
      style={{
        backgroundPositionX: "27%",
        backgroundPositionY: 30,
        backgroundSize: "172px 145px",
        backgroundRepeat: "no-repeat",
        //width: 176,
        width: "100%",
        height: 175,
      }}
    >
      <div className="subscribebar-container" style={{ zIndex: 999 }}>
        <input
          type=""
          placeholder={storyPlanNewsletter?.content?.Email}
          style={{
            width: "calc(100% - 200px)",
            border: "none",
            outline: "none",
            paddingLeft: 40,
            fontSize: 18,
            fontWeight: 400,
            color: "#6B6C72",
          }}
        />
        <Button
          variant="contained"
          bgColor="secondary"
          fontColor="white"
          width={190}
          height={55}
          style={{ minWidth: 170, fontSize: 16, fontWeight: 700 }}
        >
          {storyPlanNewsletter?.content?.Subscribe_now}
        </Button>
      </div>
    </div>
  );
}
