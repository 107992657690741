import React from "react";



const TextArea = (props) => {
  const { id, style, value, onChange } = props;

  return (
    <textarea
      style={style}
      id={id}
      name={id}
      rows="4"
      cols="50"
      value={value}
      onChange={(val) => onChange(val.target.value)}
    >
      {value}
    </textarea>
  );
};

export default TextArea;
