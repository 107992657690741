import RichTextResolver from "storyblok-js-client/richTextResolver";

function createMarkup(storyblokRichtext) {
  const resolver = new RichTextResolver();
  return {
    __html: resolver.render(storyblokRichtext),
  };
}

export const RichText = (storyblokRichtext) => {
  return <div dangerouslySetInnerHTML={createMarkup(storyblokRichtext)}></div>;
};
