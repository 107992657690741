import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { createPortal } from "react-dom";

const Overlay = () => {
  const loader = (
    <div
      className="HV-center h-100"
      style={{
        position: "absolute",
        inset: "0",
        zIndex: 99999999999,
        backgroundColor: "white",
      }}
    >
      <BeatLoader color="#FC9C52" loading={true} size={20} margin={5} />
    </div>
  );

  return <>{createPortal(loader, document.getElementById("overlay"))}</>;
};

export default Overlay;
