export const languageMapping = (lang) => {
  switch (lang) {
    case "en":
      return 1;

    case "de":
      return 4;

    case "pt":
      return 2;

    default:
      return 0;
  }
};
