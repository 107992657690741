import React, {useEffect, useState} from "react";

import {CarouselItem2, Slider} from "../../../components";
import {useStoryblok} from "services/storyblokService";
import Overlay from "components/Overlay";
import Markdown from "react-markdown";
import styles from "../../../styles/AboutUs.module.css";
import PageTemplate from "../../../components/PageTemplate";
import HeaderImg from "../../../assets/image/blog3.png";

const AboutUs = () => {
  const [professionalsFiltered, setProfessionalsFiltered] = useState([]);
  const SB_aboutUs = useStoryblok("about-us");

  useEffect(() => {
    if (SB_aboutUs?.content?.Professionals.length > 0) {
      let res = [];
      SB_aboutUs.content.Professionals.forEach((element) => {
        if (element.Featured) {
          res.push({
            job: element.Function,
            name: element.Name,
            index: element.Order,
            imgSrc: element.Photo.filename,
          });
        }
      });
      res.sort((a, b) => a.index - b.index);
      setProfessionalsFiltered(res);
    }
  }, [SB_aboutUs?.content?.Professionals]);

  if (!SB_aboutUs) {
    return <Overlay></Overlay>;
  }

  const renderProfessionals = () => {
    return professionalsFiltered.map((element) => {
      return (
        <CarouselItem2
          avatar={element?.imgSrc}
          name={element?.name}
          job={element?.job}
        ></CarouselItem2>
      );
    });
  };

  return (
    <PageTemplate
      backgroundImgSrc={HeaderImg}
      title={SB_aboutUs?.content?.Title}
    >
      <div
        className={styles.aboutUs_container}
        style={{
          margin: "80px auto 0 auto",
          maxWidth: 1100,
          padding: "0 80px",
        }}
      >
        <img src={SB_aboutUs?.content?.Image?.filename} alt="" loading={"lazy"}></img>
        <div className={styles.aboutUs_text}>
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: "#9EB152",
              display: "inline-block",
            }}
          >
            {SB_aboutUs?.content?.Title}
          </p>
          <br></br>
          <p
            style={{
              width: 60,
              paddingBottom: 15,
              whiteSpace: "nowrap",
              fontSize: 38,
              fontWeight: "bold",
              color: "#07122E",
              borderBottom: "2px solid #FC9C52",
            }}
          >
            My Perfect Meal
          </p>
          <p>
            <Markdown>{SB_aboutUs?.content?.Body}</Markdown>
          </p>
        </div>
      </div>

      <div
        className="d-flex flex-column align-items-center"
        style={{ marginTop: 120, width: "100%" }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            width: 60,
            paddingBottom: 15,
            borderBottom: "2px solid #FC9C52",
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              fontSize: 38,
              fontWeight: "bold",
              color: "#07122E",
              textAlign: "center",
            }}
          >
            {SB_aboutUs?.content?.Our_professionals_title}
          </span>
        </div>
        <p
          style={{
            maxWidth: 445,
            marginTop: 20,
            fontSize: 16,
            fontWeight: 400,
            color: "#6B6C72",
          }}
        >
          {SB_aboutUs?.content?.Our_professionals_body}
        </p>

        <div className={styles.aboutUs_slider}>
          <Slider items={2} slidesToScroll={1} space={50} center={false}>
            {renderProfessionals()}
          </Slider>
        </div>
      </div>
    </PageTemplate>
  );
};

export default AboutUs;
