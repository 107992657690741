import group77392 from "../../assets/image/group77392.svg";
import { Link } from "react-router-dom";
import maskGroup2 from "../../assets/image/maskGroup2.svg";
import group78692 from "../../assets/image/group78692.svg";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import React from "react";

const ThreeColumn = ({ story, overrideStyle, articleSection }) => {
  const history = useHistory();
  const storyContent = story?.content;

  return (
    <div style={overrideStyle} className="container-1">
      <div className="column-three-rows">
        <div className="container-3">
          <img
            src={storyContent?.Image?.filename}
            className="column-image"
            onClick={() => history.push(`/hungry-for-insights/${story?.slug}`)}
            alt={""}
            loading={"lazy"}
          ></img>
          <div className="div-inside-column">
            <div className="text-inside-column">
              <span className="text">{storyContent?.Type_of[0].Type[0]}</span>
            </div>
          </div>
        </div>
        <div className="inside-text-card">
          <Tooltip title={storyContent?.Title} placement="top">
            <span id={storyContent?._uid} className="text-1 line-clamp-2">
              {storyContent?.Title}
            </span>
          </Tooltip>

          <div className="container-8">
            <div className="blog-author">
              <img className="image" src={maskGroup2} alt={""} loading={"lazy"} />
              <span className="text-2">{storyContent?.Author}</span>
            </div>
            <div className="date-div">
              <img className="image-1" src={group78692} alt={""} loading={"lazy"} />
              <span className="text-3">{storyContent?.Reading_Time}</span>
            </div>
          </div>
          <span className="text-4 line-clamp-3">{storyContent?.Teaser}</span>
          <div className="card-link-container">
            <span className="link-inside-card">
              <Link to={`/hungry-for-insights/${story?.slug}`} key={`/hungry-for-insights/${story?.slug}`}>
                {articleSection?.Read_more}
              </Link>
              <img className="image-2" src={group77392} alt={""} loading={"lazy"}/>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeColumn;
