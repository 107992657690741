import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader, ClipLoader } from "react-spinners";
import { List, ListItem, ListItemText } from "@mui/material";

import { BsPencil, BsArrowLeft, BsLink45Deg } from "react-icons/bs";
import { Link } from "react-router-dom";

import { showToastr } from "../../../services/themeService";
import { LineEdit, Button } from "../../../components";

import BackgroundImage from "../../../assets/image/vegetarian-foods.png";

import instagram from "../../../assets/image/instagram.png";
import gmail from "../../../assets/image/gmailpng.png";
import apple from "../../../assets/image/AppleIcon.png";
import whatsapp from "../../../assets/image/whatsapp.png";
import facebook from "../../../assets/image/facebook.png";
import bluethooth from "../../../assets/image/bluethooth.png";
import messenger from "../../../assets/image/messenger.png";
import { useStoryblok } from "../../../services/storyblokService";
import styles from "./EditDefinitions.module.css";
import { Auth } from "aws-amplify";
import PageTemplate from "../../../components/PageTemplate";
import HeaderImg from "../../../assets/image/blog3.png";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 190,
    borderRadius: 25,
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

const genderOptions = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
];

const Edit = () => {
  const dispatch = useDispatch();
  const SB_definitions = useStoryblok("definitions");

  const profile = useSelector((state) => state.session.profile);
  const loading = useSelector((state) => state.session.profileLoading);
  const [editMode, setEditMode] = useState(false);

  const [group, setGroup] = useState(1);

  const refCurrentPass = useRef();
  const refNewPass = useRef();
  const refConfirmPass = useRef();

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    if (
      (!refCurrentPass.current &&
        !refNewPass.current &&
        !refConfirmPass.current) ||
      refCurrentPass.current.value === "" ||
      refNewPass.current.value === "" ||
      refConfirmPass.current.value === ""
    ) {
      showToastr("error", "Authorization Error", "All fields must be filed.");
      return;
    } else if (refNewPass.current.value !== refConfirmPass.current.value) {
      showToastr(
        "error",
        "Authorization Error",
        "New password and Confirm password don't match."
      );
      return;
    }

    const user = await Auth.currentAuthenticatedUser();
    try {
      const result = await Auth.changePassword(
        user,
        refCurrentPass.current.value,
        refNewPass.current.value
      );
      showToastr(
        "success",
        "Change password",
        "Password was changed successfully."
      );
      refCurrentPass.current.value = "";
      refNewPass.current.value = "";
      refConfirmPass.current.value = "";
      setEditMode(false);
    } catch (error) {
      showToastr("error", "Authorization Error", error.message);
    }
  };

  const handleGroup = (group) => {
    setGroup(group);
  };

  if (!profile) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="HV-center h-100">
        <ClipLoader color="#FC9C52" size={35} />
      </div>
    );
  }

  return (
    <PageTemplate
      backgroundImgSrc={HeaderImg}
      title={SB_definitions?.content?.Title}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={styles.changePasswordWrapper}>
          {group === 1 ? (
            <div>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: 20,
                }}
              >
                {SB_definitions?.content?.Change_password}
              </p>
              <div style={{ textAlign: "end" }}>
                <p className="edit-preferences">Edit</p>
                <Button
                  variant=""
                  width={40}
                  height={40}
                  style={{ padding: 0 }}
                  onClick={handleEdit}
                >
                  <BsPencil style={{ color: "#B8BABD", fontSize: 18 }} />
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ height: "100%", width: "50%" }}>
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <p className="profile-subject">
                        {SB_definitions?.content?.Current_password}
                      </p>
                      <LineEdit
                        type="password"
                        editable="true"
                        style={{
                          height: 38,
                          margin: 0,
                          padding: 10,
                          width: "140%",
                        }}
                        onChange={() => {}}
                        readOnly={!editMode}
                        ref={refCurrentPass}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="d-flex align-items-center mb-3">
                      <div>
                        <p className="profile-subject">
                          {SB_definitions?.content?.New_password}
                        </p>
                        <LineEdit
                          type="password"
                          editable="true"
                          style={{
                            height: 38,
                            margin: 0,
                            padding: 10,
                            width: "140%",
                          }}
                          onChange={() => {}}
                          readOnly={!editMode}
                          ref={refNewPass}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <div>
                        <p className="profile-subject">
                          {SB_definitions?.content?.Confirm_password}
                        </p>
                        <LineEdit
                          type="password"
                          editable="true"
                          style={{
                            height: 38,
                            margin: 0,
                            padding: 10,
                            width: "140%",
                          }}
                          onChange={() => {}}
                          readOnly={!editMode}
                          ref={refConfirmPass}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {editMode ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: 40 }}
                >
                  <Button
                    variant="contained"
                    bgColor="primary"
                    fontColor="white"
                    width="40%"
                    height={42}
                    style={{
                      background:
                        "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
                      boxShadow: "1px 9px 31px rgba(0, 0, 0, 0.25)",
                      borderadius: "25.5px",
                    }}
                    onClick={handleSave}
                  >
                    <span style={{ fontSize: 18, fontWeight: 600 }}>
                      {SB_definitions?.content?.Confirm}
                    </span>
                  </Button>
                </div>
              ) : null}
            </div>
          ) : group === 2 ? (
            <div>
              <div style={{ textAlign: "center" }}>
                <img className={"appIcon"} src={instagram}></img>
                <img className={"appIcon"} src={gmail}></img>
                <img className={"appIcon"} src={apple}></img>
                <img className={"appIcon"} src={whatsapp}></img>
                {/*<BsInstagram width="2em" className='fa-instagram'></BsInstagram>
                    <FaGoogle width="2em"></FaGoogle>
                    <BsApple width="2em"></BsApple> 
                    <BsWhatsapp width="2em"></BsWhatsapp>*/}
              </div>

              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <img className={"appIcon"} src={facebook}></img>
                <img className={"appIcon"} src={bluethooth}></img>
                <img className={"appIcon"} src={messenger}></img>
                <BsLink45Deg
                  className={"appIcon"}
                  color="#FFA141"
                ></BsLink45Deg>
              </div>
            </div>
          ) : group === 3 ? (
            <div>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontSize: 20,
                }}
              >
                Help
              </p>
              <List
                aria-label="secondary mailbox folders"
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <ListItem
                  button
                  className={styles.editDefinitons_subitem}
                  style={{ textAlign: "center" }}
                  onClick={() => handleGroup(1)}
                >
                  <ListItemText
                    primary="Send us feedback"
                    className={styles.editDefinitons_text}
                    style={{ textAlign: "center" }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={styles.editDefinitons_subitem}
                  style={{ textAlign: "center" }}
                  onClick={() => handleGroup(2)}
                >
                  <ListItemText primary="Q&A" />
                </ListItem>
                <ListItem
                  button
                  className={styles.editDefinitons_subitem}
                  style={{ textAlign: "center" }}
                  onClick={() => handleGroup(3)}
                >
                  <ListItemText primary="Delete account" />
                </ListItem>
              </List>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </PageTemplate>
  );
};

export default Edit;
