import React from 'react';


const SocialButton = (props) => {
  const { style, children, onClick } = props;
  
  return (
    <a className="social-button" style={style} onClick={onClick}>
      {children}
    </a>
  );
}

export default SocialButton;