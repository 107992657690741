import { Pagination, RecipeItem } from "../../../components";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  getCuisineList,
  getDifficultyLevelList,
  getFieldList,
  getGroceryTypes,
  getMealLevels,
  getRDDEvaluations,
  getVegetarianTypeList,
} from "../../../store/app";
import {checkAuthState, getProfile} from "../../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../services/apiService";
import Filter from "./Filter";
import {BeatLoader, GridLoader} from "react-spinners";
import MainImage from "../../../assets/image/vegetarian-foods.png";
import Markdown from "react-markdown";
import PageTemplate from "../../../components/PageTemplate";
import Search from "../../../components/Search";
import TopMenuBar from "components/TopMenuBar";
import { showToastr } from "../../../services/themeService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useStoryblok } from "services/storyblokService";
import { createDebouncedAction } from "../../../utils/debouncer"
import {Redirect} from "react-router-dom";

export const VEGETARIANFILTER = "VEGETARIANFILTER";
export const DIFFICULTYFILTER = "DIFFICULTYFILTER";
export const CUISINEFILTER = "CUISINEFILTER";
export const EVALUATIONSTARSFILTER = "EVALUATIONSTARSFILTER";

const VegetarianFoods = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const SB_filtersAndOrders = useStoryblok("filters-and-orders");
  const SB_RecipeList = useStoryblok("recipe-list");
  const SB_recipeMultilingueFields = useStoryblok("recipe-multilingue-fields");
  const SB_languages = useStoryblok("languages");

  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('vegetarianFoodsState', serializedState);
    } catch (err) {
      console.error('Could not save state', err);
    }
  };

  const loadState = () => {
    try {
      const serializedState = localStorage.getItem('vegetarianFoodsState');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      console.error('Could not load state', err);
      return undefined;
    }
  };

  const [showFilters, setShowFilters] = useState(true);

  const {
    cuisines,
    rddEvaluations,
    vegetarianTypes,
    difficultyLevels,
    language,
    languages,
  } = useSelector((state) => state.app);

  const { isAuthenticated, user, profile, loginLoading, profileLoading } = useSelector(state => state.session);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);


  const [page, setPage] = useState(1);

  const [recipeInfo, setRecipeInfo] = useState();

  const [filterOptions, setFilterOptions] = useState({
    cuisineList: [],
    vegetarianTypeList: [],
    difficultyLevelList: [],
    evaluationStar: { ranges: [] },
    keyword: "",
    sortBy: "",
  });
  const [loading, setLoading] = useState(false);
  const [sortByIndex, setSortByIndex] = useState();
  const [, setAvailableLangs] = useState([]);
  const [, setSelectedLang] = useState();

  useEffect(() => {
    if (SB_languages?.content?.Language.length > 0) {
      let aux = [];
      SB_languages?.content?.Language.forEach((element) => {
        if (element.Enabled)
          aux.push({ ...element, value: element.Id, label: element.Name });
      });
      setAvailableLangs(aux);
      setSelectedLang(aux.find((l) => l.Id === language));
    }
  }, [SB_languages, language]);


  const count = 18;

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(checkAuthState());
      setIsCheckingAuth(false);
    };
    checkAuth();
    dispatch(getMealLevels());
    dispatch(getGroceryTypes());
    dispatch(getDifficultyLevelList());
    dispatch(getFieldList());
    dispatch(getCuisineList());
    dispatch(getVegetarianTypeList());
    dispatch(getRDDEvaluations());
    dispatch(getProfile());
  }, [dispatch, language]);

  useEffect(() => {
    const savedState = loadState();
    if (savedState) {
      console.log("Setting from saved state:", savedState.meals)
      setPage(savedState.page);
      setFilterOptions(savedState.filterOptions);
      setSortByIndex(savedState.sortByIndex);
      fetchData(savedState.filterOptions, savedState.page);
    }else if (profile) {
      if (
        window &&
        window.history &&
        window.history.state &&
        window.history.state.page &&
        window.history.state.filterOptions &&
        window.history.state.meals &&
        window.history.state.sortByIndex
      ) {
        console.log("Setting from state:", window.history.state.meals)
        setPage(window.history.state.page);
        setRecipeInfo(window.history.state.meals);
        setFilterOptions({ ...window.history.state.filterOptions });
        setSortByIndex(window.history.state.sortByIndex);
      } else {
        console.log("Fetching instead")
        fetchData(
          {
            ...filterOptions,
            cuisineList: profile.cuisines.slice(),
            vegetarianTypeList: profile.vegetarianTypes.slice(),
            evaluationStar: { ranges: [] },
            difficultyLevelList: [],
          },
          1
        );
        setFilterOptions({
          ...filterOptions,
          cuisineList: profile.cuisines.slice(),
          vegetarianTypeList: profile.vegetarianTypes.slice(),
          evaluationStar: { ranges: [] },
          difficultyLevelList: [],
        });

        window.history.replaceState(
          {
            ...window.history.state,
            filterOptions: {
              ...filterOptions,
              cuisineList: profile.cuisines.slice(),
              vegetarianTypeList: profile.vegetarianTypes.slice(),
              evaluationStar: { ranges: [] },
              difficultyLevelList: [],
            },
            page: 1,
          },
          "",
          location.pathname
        );
      }
    }
  }, [profile]);


  useEffect(() => {
    saveState({
      page,
      filterOptions,
      sortByIndex
    });
  }, [page, filterOptions, sortByIndex]);

  console.log("profile: ", profile);

  const fetchData = useCallback(async (filterOptions, page) => {
    setLoading(true);

    try {
      let filter = {};

      if (filterOptions.cuisineList.length > 0)
        filter.cuisineList = filterOptions.cuisineList;
      if (filterOptions.vegetarianTypeList.length > 0)
        filter.vegetarianTypeList = filterOptions.vegetarianTypeList;
      if (filterOptions.difficultyLevelList.length > 0)
        filter.difficultyLevelList = filterOptions.difficultyLevelList;
      if (filterOptions.evaluationStar.ranges.length > 0)
        filter.evaluationStar = {
          ranges: filterOptions.evaluationStar.ranges,
        };

      filter.keyword = filterOptions.keyword;

      const data = await ApiService.getRecipes(
        page,
        count,
        filterOptions.sortBy,
        filter
      );

      setRecipeInfo(data);
      window.history.replaceState(
        { ...window.history.state, meals: data },
        "",
        location.pathname
      );
      setLoading(false);
    } catch (error) {
      console.error("Recipe error:", error);
      if (error.__CANCEL__) {
        // Request was aborted, ignore
        console.log("Request aborted:", error.message);
      } else {
        console.error("Error fetching data:", error);
        showToastr("error", "Request error", "Something went wrong");
        setLoading(false);
      }
    }
  }, []);

  const debouncedFetchData = useMemo(() =>
      createDebouncedAction(
        (filterOptions, page) => () => fetchData(filterOptions, page),
        1000 // 1 second debounce
      ),
    [fetchData] // Only recreate if fetchData changes
  );

  const handlePageChange = (val) => {
    setPage(val);
    saveState({
      page: val,
      filterOptions,
      sortByIndex
    })
    fetchData(filterOptions, val);
  };

  const handleSearch = (keyword) => {
    const newFilterOptions = { ...filterOptions, keyword: keyword };
    setFilterOptions(newFilterOptions);
    const newState = {
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex
    };

    saveState(newState);
    setPage(1);
    dispatch(debouncedFetchData(newFilterOptions, 1));
  };

  const handleFilterCheck = (filterName, newFilters) => {
      let newFilterOptions;
      switch (filterName) {
        case VEGETARIANFILTER:
          newFilterOptions = { ...filterOptions, vegetarianTypeList: newFilters };
          break;
        case DIFFICULTYFILTER:
          newFilterOptions = { ...filterOptions, difficultyLevelList: newFilters };
          break;
        case CUISINEFILTER:
          newFilterOptions = { ...filterOptions, cuisineList: newFilters };
          break;
        case EVALUATIONSTARSFILTER:
          newFilterOptions = {
            ...filterOptions,
            evaluationStar: { ranges: [...newFilters] }
          };
          break;
        default:
          newFilterOptions = filterOptions;
      }

      setFilterOptions(newFilterOptions);
      const newState = {
        page: 1,
        filterOptions: newFilterOptions,
        sortByIndex
      };
      saveState(newState);
      setPage(1);
      fetchData(newFilterOptions, 1);
  };

  const handleOrderByClick = (sortBy, newSortByIndex) => {
    if (sortBy === filterOptions.sortBy) {
      sortBy = "";
      newSortByIndex = undefined;
    }
    const newFilterOptions = { ...filterOptions, sortBy };
    setFilterOptions(newFilterOptions);
    setSortByIndex(newSortByIndex);
    const newState = {
      page: 1,
      filterOptions: newFilterOptions,
      sortByIndex: newSortByIndex
    };
    saveState(newState);
    setPage(1);
    fetchData(newFilterOptions, 1);
  };

  const resetFilters = () => {
    const initialFilterOptions = {
      cuisineList: profile.cuisines.slice(),
      vegetarianTypeList: profile.vegetarianTypes.slice(),
      evaluationStar: { ranges: [] },
      difficultyLevelList: [],
      keyword: "",
      sortBy: "",
    };
    setFilterOptions(initialFilterOptions);
    setSortByIndex(undefined);
    setPage(1);
    saveState({
      page: 1,
      filterOptions: initialFilterOptions,
      sortByIndex: undefined
    });
    fetchData(initialFilterOptions, 1);
  };

  if (isCheckingAuth || loginLoading || profileLoading || languages === null || rddEvaluations === null || cuisines === null || vegetarianTypes === null) {
    return (<div className="HV-center h-100">
      <BeatLoader color="#FC9C52" size={20} margin={5}/>
    </div>);
  }

  if (!isAuthenticated || !user) {
    console.log(`User is not authenticated: \nisAuthenticated: ${isAuthenticated}, \nuser: ${user}, \nprofile: ${profile}, \nloginLoading${loginLoading}, \nprofileLoading: ${profileLoading}`);
    return <Redirect to="/sign-in"/>;
  }

  let languageId;
  let rddEvaluationsMap;
  if(language){
    languageId = Object.entries(languages).find(([_, lang]) => lang.code === language.toUpperCase())[0];
    rddEvaluationsMap = Object.fromEntries(
      rddEvaluations.map((rddEval, i) => [
        i,
        {name: rddEval.languages.find(lang => lang.id == languageId).name, value: rddEval.name}
      ])
    );
  }

  
  return (
    <>
      <PageTemplate
        backgroundImgSrc={MainImage}
        title={SB_RecipeList?.content?.Title}
        subtitle={<Markdown>{SB_RecipeList?.content?.Body}</Markdown>}
      >
        <div
          style={{
            width: "100%",
            padding: "20px 30px",
          }}
        >
          <TopMenuBar
            selectedSortBy={sortByIndex}
            showFilters={showFilters}
            onClickFiltersBtn={() => setShowFilters(!showFilters)}
            SB_FiltersAndOrders={SB_filtersAndOrders}
            menuItems={[
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_difficulty,
                applyFilter: () => handleOrderByClick("difficultyLevel", 0),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_prep_time,
                applyFilter: () => handleOrderByClick("preparationTime", 1),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_nutrition,
                applyFilter: () => handleOrderByClick("nutrition", 2),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_evaluation,
                applyFilter: () => handleOrderByClick("preferences", 3),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_calorie,
                applyFilter: () => handleOrderByClick("calories", 4),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_protein,
                applyFilter: () => handleOrderByClick("protein", 5),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_sugar,
                applyFilter: () => handleOrderByClick("sugar", 6),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_carbos,
                applyFilter: () => handleOrderByClick("carbohydrate", 7),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fat,
                applyFilter: () => handleOrderByClick("fat", 8),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_fiber,
                applyFilter: () => handleOrderByClick("fiber", 9),
              },
              {
                name: SB_filtersAndOrders?.content?.OrderRecipe_by_date,
                applyFilter: () => handleOrderByClick("date", 10),
              },
            ]}
          ></TopMenuBar>
          <div className="d-flex w-100">
            <div
              className="col-6 col-md-4 col-lg-3"
              style={{
                display: `${showFilters ? "block" : "none"}`,
                paddingLeft: 0,
              }}
            >
              <Search
                value={filterOptions.keyword}
                placeholder={SB_filtersAndOrders?.content?.Search_Recipe}
                onChange={handleSearch}
                style={{width: "100%"}}
              ></Search>


              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Cuisine}
                filterValues={cuisines}
                filterOptionsChecked={filterOptions.cuisineList}
                filterName={CUISINEFILTER}
                applyFilters={handleFilterCheck}
                sortFunc={(pair1, pair2) => pair1[1].name.localeCompare(pair2[1].name)}
              ></Filter>
              <Filter
                SB_filterName={
                  SB_recipeMultilingueFields?.content?.VegeterianType
                }
                filterValues={vegetarianTypes}
                filterOptionsChecked={filterOptions.vegetarianTypeList}
                filterName={VEGETARIANFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Difficulty}
                filterValues={difficultyLevels}
                filterOptionsChecked={filterOptions.difficultyLevelList}
                filterName={DIFFICULTYFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>
              <Filter
                SB_filterName={SB_recipeMultilingueFields?.content?.Nutrition}
                filterValues={rddEvaluationsMap}
                filterOptionsChecked={filterOptions.evaluationStar.ranges}
                filterName={EVALUATIONSTARSFILTER}
                applyFilters={handleFilterCheck}
              ></Filter>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
              }}>
                <button
                  onClick={resetFilters}
                  style={{
                    fontSize: '0.9rem',
                    padding: '0.375rem 0.75rem',
                    minWidth: '120px'
                  }}
                  className="btn btn-secondary"
                >
                  Reset Filters
                </button>
              </div>

            </div>

            <div
              style={{paddingRight: 0}}
              className={`${showFilters ? "col-6" : "col-12"} ${
                showFilters ? "col-md-8" : "col-md-12"
              } ${showFilters ? "col-lg-9" : "col-lg-12"} m-0`}
            >
              {!loading && recipeInfo ? (
                <>
                  <div className="row m-0 w-100">
                    {recipeInfo.meals?.length > 0 ?(
                      <>
                    {recipeInfo.meals?.map((recipe, index) => {
                      return (
                        <div
                          key={index}
                          className={`${
                            showFilters ? "col-lg-4" : "col-lg-3"
                          } col-md-6`}
                        >
                          <RecipeItem
                            recipe={recipe}
                            SB_recipeMultilingueFields={
                              SB_recipeMultilingueFields
                            }
                            showFavIcon
                            clickVoting
                          />
                        </div>
                      );
                    })}
                    </>
                      ) :
                        (
                          <div className="">
                            <span>
                              No results found
                            </span>
                            </div>
                        )}
                  </div>
                </>
              ) : (
                <div className="">
                  <GridLoader
                    color="#FC9C52"
                    loading={true}
                    cssOverride={{ display: "block", margin: "auto" }}
                    size={15}
                    margin={2}
                  />
                </div>
              )}
            </div>
          </div>
          {recipeInfo?.pages > 1 && (
            <div className="d-flex justify-content-center my-5">
              <Pagination
                numPages={recipeInfo?.pages}
                page={page}
                onChangePage={(e, page) => handlePageChange(page)}
              />
            </div>
          )}
        </div>
      </PageTemplate>
    </>
  );
};

export default VegetarianFoods;
