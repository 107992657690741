import React from "react";
import PropTypes from "prop-types";

import { Topbar, Footer } from "./components";

const Main = (props) => {
  const { children, hasHeader, hasFooter, hasSubscribeNews } = props;

  return (
    <div>
      {hasHeader && <Topbar />}
      <main style={{ minHeight: 200 }}>{children}</main>
      {hasFooter && <Footer hasSubscribeNews={hasSubscribeNews} />}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
