import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import ReduxToastr from "react-redux-toastr";

import store from "./store";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
      <App />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />

  </Provider>
);

serviceWorker.unregister();
