import React, { useState } from "react";
import { Auth } from "aws-amplify";

import { Button, LineEdit } from "../../../components";
import { history } from "../../../store/history";
import Roma from "../../../assets/image/RomaFruta.png";
import { useStoryblok } from "../../../services/storyblokService";
import DotsImage from "../../../assets/image/background-dots3.png";

const ForgotPassword = () => {
  const SB_forgotPassword = useStoryblok("forgotpassword");

  const [email, setEmail] = useState("");

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const data = await Auth.forgotPassword(email);
      console.log(data);
      history.push("/reset-pass", { email });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row" style={{ height: "100%", width: "100%", margin: 0 }}>
        <div
          className="col-md-6 sign-img-container"
          style={{
            maxWidth: 960,
            padding: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Roma} style={{ width: "100%" }}></img>
        </div>
        <div className="col-md-6 sign-auth-form">
          <div
            style={{
              backgroundImage: `url(${DotsImage})`,
              height: "100%",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div className="text-center mt-4">
              <h1 className="h2">{SB_forgotPassword?.content?.Title}</h1>
              <p className="lead text-center">
                {SB_forgotPassword?.content?.Body_Mesg}
              </p>
            </div>

            <div
              style={{
                maxWidth: 400,
                margin: "auto",
                marginTop: 30,
                textAlign: "center",
              }}
            >
              <form className="m-sm-4" onSubmit={handleSend}>
                <LineEdit
                  type="text"
                  name="email"
                  placeholder={SB_forgotPassword?.content?.Enter_e_mail_mesg}
                  value={email}
                  onChange={(val) => setEmail(val)}
                />
                <Button
                  variant="contained"
                  bgColor="primary"
                  fontColor="white"
                  onClick={handleSend}
                  width="200px"
                  height={50}
                  style={{
                    background:
                      "linear-gradient(180deg, #FAA72D 0%, #F28231 100%)",
                    boxShadow: "1px 9px 31px rgba(0, 0, 0, 0.25)",
                    bordeRadius: "25.5px",
                    padding: "0",
                  }}
                >
                  {SB_forgotPassword?.content?.Send}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
