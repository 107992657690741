import React, { useRef, useEffect } from 'react';

function useClickOutside(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
};

export default function ClickOutside(props) {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, props.handler);

  return <div ref={wrapperRef}>{props.children}</div>;
};