import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";

import { Button, Calendar } from "../../../components";
import { history } from "../../../store/history";

import { clearWeeklyPlan } from "../../../store/app/actions";
import { ApiService } from "../../../services/apiService";
import { showToastr } from "../../../services/themeService";
import { useStoryblok } from "../../../services/storyblokService";
import PageTemplate from "../../../components/PageTemplate";
import MainImage from "../../../assets/image/vegetarian-foods.png";

const WeeklyPlans = (props) => {
  const dispatch = useDispatch();
  const SB_weeklyPlans = useStoryblok("weekly-plan");
  const weeklyPlans = useSelector((state) => state.app.weeklyPlans);

  // const profile = useSelector(state => state.session.profile);

  // const language = profile?.langId ?? 1;

  const [meals, setMeals] = useState(null);

  useEffect(() => {
    if (!weeklyPlans) return;

    let meals = [];
    weeklyPlans.map((weeklyPlan) => {
      weeklyPlan.meals.map((meal) => {
        meals.push({
          id: meal.id,
          amount: meal.amount,
        });
      });
    });
    setMeals(meals);
  }, [weeklyPlans]);

  const handleClear = () => {
    ApiService.deleteMyWeeklyPlan()
      .then((data) => {
        console.log("data:", data);
        showToastr("info", "Success", "Successfully deleted weekly plan");
        setMeals([]);
        dispatch(clearWeeklyPlan());
      })
      .catch((e) => {
        console.log("Error deleting weekly plan:", e);
        showToastr(
          "error",
          "Error",
          "An error occurred while deleting the weekly plan"
        );
      });
  };

  const handleGroceries = () => {
    history.push("/groceries", { meals: meals, from: "/weekly-plans" });
  };

  return (
    <PageTemplate
      title={SB_weeklyPlans?.content?.Title}
      backgroundImgSrc={MainImage}
    >
      <div style={{ padding: "5% 5%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "10px 0",
          }}
        >
          <Button
            variant="contained"
            bgColor="primary"
            fontColor="white"
            className="mr-2"
            height={38}
            onClick={handleClear}
          >
            {SB_weeklyPlans?.content?.Clear_list}
          </Button>
          <Button
            variant="contained"
            bgColor="secondary"
            fontColor="white"
            height={38}
            onClick={handleGroceries}
          >
            <FiShoppingCart
              style={{ fontSize: 16, color: "white", marginRight: 10 }}
            />
            {SB_weeklyPlans?.content?.Groceries}
          </Button>
        </div>
        <Calendar
          editable
          // onChangeWeek={(meals) => handleChangeWeek(meals)}
        />
      </div>
    </PageTemplate>
  );
};

export default WeeklyPlans;
