import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoryblokClient from "storyblok-js-client";

const Storyblok = new StoryblokClient({
  //TODO: Remove hardcoded access token
  accessToken: "Z621f1nlepsmJNp4sYMPEgtt",
  cache: {
    clear: "auto",
    type: "memory",
  },
});

export function useStoryblok(slug, filterQuery) {
  const [story, setStory] = useState(null);
  const language = useSelector((state) => state.app.language);

  //TODO: Use a deep comparison to check if filterQuery has changed
  const filterQueryStr = JSON.stringify(filterQuery);

  useEffect(() => {
    let params = {
      version: "published",
      language: language?.toLowerCase(),
      fallback_lang: "en",
      cv: Date.now(),
    };

    if (slug) {
      Storyblok.get(`cdn/stories/${slug}`, {
        ...params,
      })
        .then((response) => {
          setStory(response.data.story);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (filterQuery) {
      console.log(language);
      console.log(filterQuery);
      if (filterQuery.sort_by) {
        filterQuery.sort_by = changeLanguageCode(filterQuery.sort_by, language);
      }
      Storyblok.get(`cdn/stories`, {
        ...params,
        ...filterQuery,
      })
        .then((response) => {
          setStory({
            stories: response.data.stories,
            pages: Math.ceil(response.total / response.perPage),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [slug, language, filterQueryStr]);

  return story;
}

function changeLanguageCode(inputString, languageCode) {
  const pattern = /(__i18n__)(\w{2})/;
  const replacement = "$1" + languageCode;
  const outputString = inputString.replace(pattern, replacement);
  return outputString;
}
