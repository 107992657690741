import React from "react";
import facebookIcon from "../../assets/image/facebook.png";
import appleIcon from "../../assets/image/AppleIcon.png";
import googleIcon from "../../assets/image/google.png";

const SocialSignButton = (props) => {
  const { google, facebook, apple, children, onClick } = props;

  return (
    <div style={{ color: "white" }} onClick={onClick}>
      {google ? (
        <img
          src={googleIcon}
          style={{
            width: "50px",
            height: 50,
            margin: "0px 20px",
            cursor: "pointer",
          }}
          alt={"google"}
          loading={"lazy"}
        ></img>
      ) : null}
      {facebook ? (
        <img
          src={facebookIcon}
          style={{
            width: "50px",
            height: 50,
            margin: "0px 20px",
            cursor: "pointer",
          }}
          alt={"facebook"}
          loading={"lazy"}
        ></img>
      ) : null}
      {apple ? (
        <img
          src={appleIcon}
          style={{
            width: "50px",
            height: 50,
            margin: "0px 20px",
            cursor: "pointer",
          }}
          alt={"apple"}
          loading={"lazy"}
        ></img>
      ) : null}
      <span style={{ width: 8 }}></span>
      {children}
    </div>
  );
};

export default SocialSignButton;

