import { Button, Rating } from "@mui/material";
import React, { useState } from "react";
import dialogImage from "../assets/image/image 55.svg";
import { updateVoting } from "../store/app";
import { useDispatch } from "react-redux";
import Dialog from "./Dialog";
import TextArea from "./TextArea";

const VotingMealDialog = ({ openDialog, onCloseHandler, title, recipe }) => {
  const dispatch = useDispatch();
  const voteInfo = recipe.votes.comments.find((vote) => vote.isOwn);
  const [rating, setRating] = useState(voteInfo?.star ?? 0);
  const [comment, setComment] = useState(voteInfo?.comment ?? "");

  const handleApply = () => {
    dispatch(updateVoting(recipe.id, rating, comment));
    onCloseHandler();
  };

  return (
    <Dialog
      open={openDialog}
      fullWidth={true}
      maxWidth="md"
      onClose={onCloseHandler}
    >
      <div
        style={{
          display: "flex",
          width: "95%",
        }}
      >
        <img style={{ width: "50%" }} src={dialogImage} alt={""} loading={"lazy"}></img>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            width: "50%",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <p style={{ fontSize: 18, fontWeight: 600 }}>{title}</p>
            <Rating
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            <TextArea
              style={{ width: "100%" }}
              value={comment}
              onChange={(val) => setComment(val)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              onClick={() => handleApply()}
              sx={{
                "&.MuiButtonBase-root": {
                  backgroundColor: "#FAA72D",
                  fontSize: "19px",
                  fontWeight: "500",
                  textTransform: "unset",
                  borderRadius: "25px",
                  padding: "0 40px",
                },
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VotingMealDialog;
