export const COUNTRY_LOADING = 'COUNTRY_LOADING'
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS'
export const COUNTRY_ERROR = 'COUNTRY_ERROR'

export const CUISINE_LOADING = 'CUISINE_LOADING'
export const CUISINE_SUCCESS = 'CUISINE_SUCCESS'
export const CUISINE_ERROR = 'CUISINE_ERROR'

export const VEGETARIAN_LOADING = 'VEGETARIAN_LOADING'
export const VEGETARIAN_SUCCESS = 'VEGETARIAN_SUCCESS'
export const VEGETARIAN_ERROR = 'VEGETARIAN_ERROR'

export const INGREDIENT_LOADING = 'INGREDIENT_LOADING'
export const INGREDIENT_SUCCESS = 'INGREDIENT_SUCCESS'
export const INGREDIENT_ERROR = 'INGREDIENT_ERROR'

export const CONTENT_LOADING = 'CONTENT_LOADING'
export const CONTENT_SUCCESS = 'CONTENT_SUCCESS'
export const CONTENT_ERROR = 'CONTENT_ERROR'

export const RECOMMENDATION_SUCCESS = 'RECOMMENDATION_SUCCESS'
export const RECOMMENDATION_ERROR = 'RECOMMENDATION_ERROR'

export const RECIPES_LOADING = 'RECIPES_LOADING'
export const RECIPES_SUCCESS = 'RECIPES_SUCCESS'
export const RECIPES_ERROR = 'RECIPES_ERROR'

export const RECIPE_LOADING = 'RECIPE_LOADING'
export const RECIPE_SUCCESS = 'RECIPE_SUCCESS'
export const RECIPE_ERROR = 'RECIPE_ERROR'

export const PREFERENCE_LOADING = 'PREFERENCE_LOADING'
export const PREFERENCE_SUCCESS = 'PREFERENCE_SUCCESS'
export const PREFERENCE_ERROR = 'PREFERENCE_ERROR'

export const VOTING_LOADING = 'VOTING_LOADING'
export const VOTING_SUCCESS = 'VOTING_SUCCESS'
export const VOTING_ERROR = 'VOTING_ERROR'

export const PREFER_LOADING = 'PREFER_LOADING'
export const PREFER_SUCCESS = 'PREFER_SUCCESS'
export const PREFER_ERROR = 'PREFER_ERROR'

export const DIFFICULTY_LOADING = 'DIFFICULTY_LOADING'
export const DIFFICULTY_SUCCESS = 'DIFFICULTY_SUCCESS'
export const DIFFICULTY_ERROR = 'DIFFICULTY_ERROR'

export const FIELD_LOADING = 'FIELD_LOADING'
export const FIELD_SUCCESS = 'FIELD_SUCCESS'
export const FIELD_ERROR = 'FIELD_ERROR'

export const LANGUAGES_LOADING = 'LANGUAGES_LOADING'
export const LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS'
export const LANGUAGES_ERROR = 'LANGUAGES_ERROR'
export const LANGUAGE_SELECTED = 'LANGUAGE_SELECTED'

export const RDD_EVAL_LOADING = 'RDD_EVAL_LOADING';
export const RDD_EVAL_SUCCESS = 'RDD_EVAL_SUCCESS';
export const RDD_EVAL_ERROR = 'RDD_EVAL_ERROR';

export const WEEKLY_LOADING = 'WEEKLY_LOADING'
export const WEEKLY_SUCCESS = 'WEEKLY_SUCCESS'
export const WEEKLY_ERROR = 'WEEKLY_ERROR'

export const GROCERIES_LOADING = 'GROCERIES_LOADING'
export const GROCERIES_SUCCESS = 'GROCERIES_SUCCESS'
export const GROCERIES_ERROR = 'GROCERIES_ERROR'

export const GROCERY_TYPES_LOADING = 'GROCERY_TYPES_LOADING'
export const GROCERY_TYPES_SUCCESS = 'GROCERY_TYPES_SUCCESS'
export const GROCERY_TYPES_ERROR = 'GROCERY_TYPES_ERROR'

export const MEALLEVELS_LOADING = 'MEALLEVELS_LOADING'
export const MEALLEVELS_SUCCESS = 'MEALLEVELS_SUCCESS'
export const MEALLEVELS_ERROR = 'MEALLEVELS_ERROR'
