import * as types from "./actionTypes";
import { createDebouncedAction } from "../../utils/debouncer";



export const setWeb_Menu = createDebouncedAction(
  (data) => ({
    type: types.WEB_MENU,
    web_menu: data,
  }),
  200  // 200ms debounce time
);

export const setLanguages = createDebouncedAction(
  (data) => ({
  type: types.LANGUAGES,
  languages: data,
}),
200
);

export const setBanners = (data) => ({
  type: types.BANNERS,
  banners: data,
});

export const setLogo = (data) => ({
  type: types.LOGO,
  logo: data,
});

export const setAboutUs = (data) => ({
  type: types.ABOUTUS,
  aboutUs: data,
});
export const setLogoutMsg = createDebouncedAction(
  (data) => ({
  type: types.LOGOUTMSG,
  logoutMsg: data,
  }),
200
);

export const setArticlesSection = (data) => ({
  type: types.ARTICLESSECTION,
  articlesSection: data,
});

export const setArticles = (data) => ({
  type: types.ARTICLES,
  articles: data,
});

export const setHowItWorks = (data) => ({
  type: types.HOWITWORKS,
  howItWorks: data,
});

export const clean = () => ({
  type: types.CLEAN,
});
