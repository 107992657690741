import React, { useState } from "react";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@mui/material";

import { SignEdit, Button } from "../../../components";
import { showToastr } from "../../../services/themeService";
import { registerUser } from "../../../store/session";

import DotsImage from "../../../assets/image/background-dots3.png";

import Melancia from "../../../assets/image/melanciaregisto.png";
import { Topbar, Footer } from "./../../../layouts/Main/components";
import { useStoryblok } from "../../../services/storyblokService";

import { validateEmail } from "../../../utils/emailValidation";

const Signup = () => {
  const storyPlan = useStoryblok("sign-in-or-sign-up");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.session.registerLoading);

  const [view, setView] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [checkedError, setCheckedError] = useState(false);

  const handleSignUp = async () => {
    let hasError = false;

    if (!email) {
      showToastr("error", "Invalid Request", "Please input email.");
      setEmailError(true);
      hasError = true;
    } else if(!validateEmail(email)){
      showToastr("error", "Invalid Request", "Please input a valid email.");
      setEmailError(true);
      hasError = true;
    }else {
      setEmailError(false);
    }

    if (!password) {
      showToastr("error", "Invalid Request", "Please input password.");
      setPasswordError(true);
      hasError = true;
    } else {
      setPasswordError(false);
    }

    if (!view && password !== confirmPassword) {
      showToastr(
        "error",
        "Invalid Request",
        "Password confirmation doesn't match Password."
      );
      setConfirmPasswordError(true);
      hasError = true;
    } else {
      setConfirmPasswordError(false);
    }

    if (!checked) {
      showToastr("error", "Invalid Request", "Please read terms and check.");
      setCheckedError(true);
      hasError = true;
    } else {
      setCheckedError(false);
    }

    if (hasError) return;

    dispatch(registerUser(email, password));
  };

  if (loading) {
    return (
      <div className="HV-center h-100">
        <BeatLoader color="#FC9C52" size={20} margin={5} />
      </div>
    );
  }

  return (
    <div>
      <Topbar />
      <div className="row" style={{ height: "100%", width: "100%", margin: 0 }}>
        <div
          className="col-md-6 sign-img-container"
          style={{
            maxWidth: 960,
            padding: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Melancia} style={{ width: "100%" }} alt={""}></img>
        </div>

        <div className="col-md-6 sign-auth-form">
          <div
            style={{
              backgroundImage: `url(${DotsImage})`,
              height: "100%",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top",
            }}
          >
            <div>
              <div style={{ width: 450 }}>
                <p
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "#07122E",
                    marginBottom: 40,
                    textAlign: "center",
                  }}
                >
                  {storyPlan?.content?.Sign_up}
                </p>
                <SignEdit
                  email
                  type="text"
                  name="email"
                  placeholder={storyPlan?.content?.E_mail}
                  value={email}
                  onChange={(val) => setEmail(val)}
                  className={emailError ? "input-error" : ""}
                />
                <SignEdit
                  pass
                  eye
                  type="text"
                  name="pass"
                  view={view}
                  placeholder={storyPlan?.content?.Password}
                  onViewClick={() => setView(!view)}
                  value={password}
                  onChange={(val) => setPassword(val)}
                  className={passwordError ? "input-error" : ""}
                />
                {view ? null : (
                  <SignEdit
                    pass
                    type="text"
                    name="confirm-pass"
                    placeholder={storyPlan?.content?.Confirm_passwd}
                    value={confirmPassword}
                    onChange={(val) => setConfirmPassword(val)}
                    className={confirmPasswordError ? "input-error" : ""}
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checkedB"
                      style={{ fontSize: 16, margin: "30px 0" }}
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ color: "#6B6C72", fontSize: 14 }}>
                      {storyPlan?.content?.Phrase_terms_conditions}
                    </span>
                  }
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderColor: checkedError ? "red" : "",
                    borderWidth: checkedError ? "2px" : "",
                    borderStyle: checkedError ? "solid" : "none",
                    borderRadius: "4px",
                    paddingLeft: checkedError ? "10px" : "12px", // Adjust the padding
                    boxSizing: "border-box",


                  }}
                />
              </div>
              <div style={{ maxWidth: 450 }}>
                <div
                  className="d-flex"
                  style={{
                    marginBottom: 45,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 45,
                  }}
                >
                  <Button
                    variant="contained"
                    bgColor="primary"
                    fontColor="white"
                    width={190}
                    height={42}
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "21px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      borderRadius: "40px",
                    }}
                    onClick={handleSignUp}
                  >
                    {storyPlan?.content?.Sign_up}
                  </Button>
                </div>
                {/* <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    marginBottom: 20,
                  }}
                >
                  <hr style={{ width: "33%" }}></hr>
                  <p className="or-login">
                    {storyPlan?.content?.SocialNetSign}
                  </p>
                  <hr style={{ width: "33%" }}></hr>
                </div> */}

                {/* <div
                  className="d-flex"
                  style={{ marginBottom: 40, justifyContent: "center" }}
                >
                  <SocialSignButton
                    facebook
                    bgColor="#1673EA"
                    onClick={handleFacebook}
                  ></SocialSignButton>
                  <SocialSignButton
                    google
                    bgColor="#DC4234"
                    onClick={handleGoogle}
                  ></SocialSignButton>
                  <SocialSignButton
                    apple
                    bgColor="#9CA5A7"
                    onClick={handleApple}
                  ></SocialSignButton>
                </div> */}
                <p className="create-account-paragraph">
                  {storyPlan?.content?.Already_account}{" "}
                  <Link
                    to="/sign-in"
                    style={{
                      borderBottom: "1px solid gray",
                      color: "#07122E",
                      textDecoration: "none",
                      fontWeight: 600,
                    }}
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
